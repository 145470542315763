import React from "react";
import NosPartenaires from "../../components/NosPartenaires";
import "./EtudierESISA.css";

export default function Etudier_ESISA() {
  const partenaires = [
    { href: "https://www.ensiie.fr/", src: "images/partenaire/ensiie1.png" },
    {
      href: "https://polytech.univ-amu.fr/",
      src: "images/partenaire/poly1.png",
    },
    { href: "https://www.eilco-ulco.fr/", src: "images/partenaire/cote1.png" },
    { href: "https://www.isima.fr/", src: "images/partenaire/isima1.png" },
    { href: "https://www.univ-lyon2.fr/", src: "images/partenaire/lyon1.png" },
    {
      href: "https://univ-cotedazur.fr/",
      src: "images/partenaire/cotedazur.png",
    },

    {
      href: "https://www.univ-lorraine.fr/",
      src: "images/partenaire/lor1.png",
    },
    {
      href: "https://www.univ-paris8.fr/",
      src: "images/partenaire/paris81.png",
    },
    {
      href: "https://www.u-bourgogne.fr/",
      src: "images/partenaire/unvbou1.png",
    },
    {
      href: "https://www.univ-littoral.fr/",
      src: "images/partenaire/unvop1.png",
    },
    { href: "https://www.uca.fr/", src: "images/partenaire/uca1.png" },
    {
      href: "https://www.univ-paris13.fr/",
      src: "images/partenaire/paris131.png",
    },
    { href: "https://www.univ-lille.fr/", src: "images/partenaire/lille1.png" },
    { href: "https://www.lis-lab.fr/", src: "images/partenaire/lis1.png" },
  ];
  return (
    <div className="container" style={{ overflow: "auto" }}>
      <div class="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
            Étudier à l'ESISA
          </span>
        </h4>
      </div>

      <div className="introduction">
        <p>
          À l'ESISA, nous façonnons les leaders technologiques de demain. Située
          sur un terrain de 5000 m², notre école est au cœur de l'innovation en
          ingénierie des systèmes d'information, avec un accent particulier sur
          l'Intelligence Artificielle (IA) et la cybersécurité. Nous nous
          engageons à offrir une formation d'excellence, propulsée par des
          recherches de pointe et une pédagogie guidée par l'excellence.
        </p>
        <p>
          Fondée par le Dr Khalid Mekouar, un ingénieur de renom et lauréat du
          prestigieux prix Sadi Carnot, l'ESISA bénéficie d'un leadership
          visionnaire qui oriente notre école vers l'excellence académique et
          l'innovation technologique.
        </p>
      </div>
      <div style={{ marginBottom: "3rem", marginTop: "2rem" }}>
        <a
          href="/brochure.pdf"
          download="Brochure_ESISA.pdf"
          className="brochure-button"
        >
          Télécharger la Brochure 📄
        </a>
      </div>
      <div className="work-environment">
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Un Environnement de Travail Inspirant
        </h2>
        <p>
          Nichée dans un écrin de verdure de 5000 m², notre école offre un cadre
          de travail serein et stimulant, à quelques minutes du centre-ville. Ce
          havre de paix est conçu pour encourager la créativité et l'innovation,
          soutenu par des infrastructures modernes et des espaces de
          collaboration ouverts.
        </p>
      </div>
      <div className="conference-room">
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Une Salle de Conférence de Haute Technologie
        </h2>
        <p>
          Notre salle de conférence de 200 places est équipée des dernières
          technologies de l'information et de la communication. Elle est idéale
          pour des conférences internationales, des séminaires de haut niveau,
          et des événements académiques, garantissant une expérience
          d'apprentissage interactive et connectée.
        </p>
      </div>
      <br />

      <div className="cleanroom">
        {" "}
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Une Salle Blanche à la Pointe de la Technologie
        </h2>{" "}
        <p>
          {" "}
          Notre salle blanche est équipée de machines de calcul haute
          performance et de solutions cloud, offrant à nos étudiants un
          environnement optimal pour mener des recherches avancées et développer
          des projets innovants en IA et cybersécurité. Cette infrastructure de
          pointe soutient notre engagement envers l'excellence et l'innovation.{" "}
        </p>{" "}
      </div>
      <div className="amphi-theatre">
        {" "}
        <br />
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Un Amphithéâtre de Haute Technologie
        </h2>
        <p>
          Notre amphithéâtre de 100 places est équipé des dernières technologies
          de l'information et de la communication. Il est idéal pour des
          visioconférences, des séminaires internationaux, et des cours à
          distance, garantissant une expérience d'apprentissage interactive et
          connectée.
        </p>
      </div>
      <div className="pedagogical-team">
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Une Équipe Pédagogique d'Exception
        </h2>
        <p>
          Nous sommes fiers de notre corps enseignant, composé principalement de
          docteurs d'état et d'experts internationaux en IA et cybersécurité.
          Nos professeurs, ayant exercé dans les plus grandes entreprises
          mondiales, sont parmi les meilleurs dans le domaine de l'IT au Maroc.
          Leur expertise enrichit considérablement notre programme, offrant aux
          étudiants une formation de haut niveau et une vision globale des
          enjeux technologiques actuels.{" "}
        </p>
      </div>
      <div className="active-pedagogy">
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Une Pédagogie Active et Innovante
        </h2>
        <p>
          Nous offrons une formation rigoureuse en ingénierie informatique, avec
          une spécialisation en IA et cybersécurité. Nos étudiants sont formés à
          la conduite de projets complexes et au travail d'équipe, tout en
          développant des compétences cruciales pour faire face aux défis de la
          transformation numérique.
        </p>
      </div>
      <div className="ambitious-goals" style={{ overflow: "auto" }}>
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Des Objectifs Ambitieux
        </h2>
        <p>
          L'ESISA se positionne comme une grande école d'ingénierie
          informatique, préparant ses diplômés à occuper des postes de
          responsabilité dans des domaines clés tels que l'IA et la
          cybersécurité. Nous intégrons des stages internationaux pour offrir
          une perspective globale et renforcer les compétences pratiques.
        </p>
      </div>
      <div className="international-exposure" style={{ overflow: "auto" }}>
        <h2
          style={{
            fontSize: "28px",
            padding: "1rem",
            color: "rgb(75, 182, 234)",
            fontWeight: "bold",
          }}
          className="section-title"
        >
          Une Ouverture à l'International
        </h2>
        <p>
          Nos étudiants ont l'opportunité de réaliser des stages à l'étranger
          dans des entreprises renommées en France, au Canada, aux États-Unis et
          au Sénégal. Nous avons établi des partenariats avec des institutions
          prestigieuses pour offrir une expérience éducative internationale
          inégalée.
        </p>

        <div className="container" style={{ overflow: "auto" }}>
          <div className="sidebar">
            <div className="widget search-widget"></div>
            <div className="widget partners-section">
              <div className="partners-heading" style={{ textAlign: "center" }}>
                <h5
                  style={{
                    color: "#4cb6ea",
                    fontWeight: "800",
                    marginBottom: "2rem",
                  }}
                >
                  NOS PARTENAIRES EN FRANCE
                </h5>
              </div>
              <div className="partners-thumbnail"></div>
              <div className="partners-gallery">
                {partenaires.map((partenaire, index) => (
                  <div
                    key={index}
                    className="partner-item"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      href={partenaire.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="partner-image"
                        src={partenaire.src}
                        alt=""
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
