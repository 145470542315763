import React from 'react';
import './AdmisEtranger.css'; 

export default function NosPartenairesNew() {

  const partenaires = [
		{ href: "https://www.ensiie.fr/", src: "images/partenaire/ensiie1.png" },
		{ href: "https://polytech.univ-amu.fr/", src: "images/partenaire/poly1.png" },
		{ href: "https://www.eilco-ulco.fr/", src: "images/partenaire/cote1.png" },
		{ href: "https://www.isima.fr/", src: "images/partenaire/isima1.png" },
		{ href: "https://www.univ-lyon2.fr/", src: "images/partenaire/lyon1.png" },
    { href: "https://www.univ-amu.fr/", src: "images/partenaire/amu.png" },
		{ href: "https://www.univ-lorraine.fr/", src: "images/partenaire/lor1.png" },
		{ href: "https://www.univ-paris8.fr/", src: "images/partenaire/paris81.png" },
		{ href: "https://www.u-bourgogne.fr/", src: "images/partenaire/unvbou1.png" },
		{ href: "https://www.univ-littoral.fr/", src: "images/partenaire/unvop1.png" },
		{ href: "https://www.uca.fr/", src: "images/partenaire/uca1.png" },
		{ href: "https://www.univ-paris13.fr/", src: "images/partenaire/paris131.png" },
		{ href: "https://www.univ-lille.fr/", src: "images/partenaire/lille1.png" },
		{ href: "https://www.lis-lab.fr/", src: "images/partenaire/lis1.png" },
    { href: "https://univ-cotedazur.fr/", src: "images/partenaire/cotedazur.png" },
		{ href: "https://www.iscid-co.fr/", src: "images/partenaire/iscid.png" },

	  ];

  return (
    <div className="widget partners-section">
      <div className="partners-heading" style={{ textAlign: "center" }}>
      <h1 
  className='afterrr' 
  style={{ 
    fontSize: '2.5rem', 
    fontWeight: '700', 
    marginBottom: '1rem', // Réduit pour rapprocher le <hr>
    color: '#4cb6ea' 
  }}
>
  NOS PARTENAIRES EN FRANCE
</h1>
<hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
      </div>

      <div className="partners-thumbnail"></div>
      <div className="partners-gallery">
        {partenaires.map((partenaire, index) => (
          <div
            key={index}
            className="partner-item"
            style={{ textAlign: "center" }}
          >
            <a
              href={partenaire.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="partner-image"
                src={partenaire.src}
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </a>
          </div>
        ))}
      </div>
    </div>

  );
}
