import React from 'react';
import './Hackaton.css'; // Assure-toi que ce fichier CSS est bien lié
import { useNavigate } from 'react-router-dom'; // Import de useNavigate

const Hackaton = () => {


    const navigate = useNavigate(); // Initialisation de navigate

    const handleRegisterClick = () => {
      navigate('/hackatonInscription'); // Redirection vers la page d'inscription
    };
  
  return (
    <div className="hackaton-container">
      <header className="hackaton-header">
        <h1 style={{color:"white", fontWeight:"bold"}} className="hackaton-title">Esisa Junior Developers Hackaton</h1>
        <p className="hackaton-subtitle">Un défi technologique pour les lycéens !</p>
      </header>

      {/* Image en haut */}
      <div className="hackaton-image-container">
      </div>

      <div className="register-button-container">
        <button onClick={handleRegisterClick} className="register-button">S'inscrire</button>
        </div>

      <section className="hackaton-details">
        <h2 className="section-title">Les prix</h2>
        <div className="hackaton-prizes">
          <div className="prize">
            <h3>1er Prix</h3>
            <p>6000 DH</p>
          </div>
          <div className="prize">
            <h3>2ème Prix</h3>
            <p>4500 DH</p>
          </div>
          <div className="prize">
            <h3>3ème Prix</h3>
            <p>3000 DH</p>
          </div>
        </div>

        <h2 className="section-title">Programme</h2>
        <ul className="hackaton-schedule">
          <li><strong>14h00</strong>: Accueil et enregistrement (Vérification des documents, Distribution des badges et welcome pack)</li>
          <li><strong>14h30</strong>: Cérémonie d'ouverture (Mot de bienvenue, Présentation de l'ESISA, Annonce du thème et des règles)</li>
          <li><strong>15h00</strong>: Formation pré-hackaton</li>
          <li><strong>16h00</strong>: Questions/Réponses</li>
          <li><strong>16h30</strong>: Début officiel du hackathon (Support technique continu, Mentorat par nos étudiants et professeurs)</li>
          <li><strong>18h30</strong>: Délibération et remise des prix (Annonce des gagnants, Remise des prix, Photos et clôture)</li>
        </ul>

        <h2 className="section-title">Documents requis</h2>
        <ul className="required-docs">
          <li>Carte d'identité</li>
          <li>Autorisation parentale pour mineurs</li>
        </ul>

        <h2 className="section-title">Compétences requises</h2>
        <p>Des bases en programmation sont recommandées mais non obligatoires.</p>

        <h2 className="section-title">Inscriptions</h2>
        <p>Ouvert aux lycéens de 16 à 18 ans. L'inscription est gratuite via le formulaire en ligne :</p>
        <a href="https://www.esisa.ac.ma/juniordevelopers" className="registration-link">Inscription en ligne</a>
        <p><strong>Dernier délai d'inscription :</strong> 23/04/2025 à minuit</p>
     
      </section>

      <footer className="hackaton-footer">
        <p>&copy; 2025 ESISA - Tous droits réservés</p>
      </footer>
    </div>
  );
};

export default Hackaton;
