import React from 'react';
import { Link } from 'react-router-dom';

export default function NavbarMob() {
  return (
    <div id="mg-responsive-navigation" className="dl-menuwrapper">
      <button className="dl-trigger">Menu</button>
      <ul className="dl-menu">
        <li className="active"><Link to="/">ACCUEIL</Link></li>
        
        <li className="menu-item mg-parent-menu">
          <a>A PROPOS DE L'ESISA</a>
          <ul className="dl-submenu">
            <li><Link to="/Mot_president">Mot du président</Link></li>
            <li><Link to="/Etudier_ESISA">Etudier à l'ESISA</Link></li>
            <li><Link to="/reconnuEsisa">Reconnaissance de l'école</Link></li>
            <li><Link to="/Objectifs_ESISA">Objectifs de l'ESISA</Link></li>
            {/* <li><Link to="/Conferences_ESISA">Conférences à l'ESISA</Link></li> */}
            <li><Link to="/Conferences_etranger">Conférences à l'étranger</Link></li>
            <li><Link to="/Workshop">Workshop</Link></li>
            <li><Link to="/Themes_recherche">Thèmes de recherche</Link></li>
          </ul>
        </li>
        
        <li className="menu-item mg-parent-menu">
          <a>ENSEIGNEMENT</a>
          <ul className="dl-submenu">
            <li><Link to="/Systeme_enseignement">Système d'enseignement</Link></li>
            <li><Link to="/Procedure_admission">Procédure d'admission</Link></li>
            <li><Link to="/Bac3">Licence</Link></li>
            <li>
              <a>Master</a>
              <ul className="dl-submenu">
                <li><Link to="/Bac5IS2IA">IS2IA</Link></li>
                <li><Link to="/Bac5ISITN">ISITN</Link></li>
              </ul>
            </li>
            <li><Link to="/Ccna">Formation CCNA (CISCO)</Link></li>
          </ul>
        </li>
        
        <li className="menu-item mg-parent-menu">
          <a>L'INTERNATIONAL</a>
          <ul className="dl-submenu">
            <li><Link to="/ESISA_International">ESISA et l'International</Link></li>
            <li><Link to="/Admis_Etranger">Admission partenaires universitaires</Link></li>
          </ul>
        </li>
        
        <li className="menu-item mg-parent-menu">
          <a>ENTREPRISES ET STAGES</a>
          <ul className="dl-submenu">
            <li><Link to="/ESISAEntreprises">ESISA-Entreprises</Link></li>
            <li><Link to="/Stages">Stages</Link></li>
            <li><Link to="/Emplois">Emplois</Link></li>
          </ul>
        </li>
        
        <li className="menu-item mg-parent-menu">
          <a>AUTRES</a>
          <ul className="dl-submenu">
            <li><Link to="/esisa-analytica">Laboratoire de recherche (LEA)</Link></li>
            <li><Link to="/esisa-c19">Incubateur Jeux Vidéos C-19</Link></li>
            <li><Link to="/esisa-cube">Incubateur d'entreprises (ESISA CUBE)</Link></li>
            <li><Link to="/hackaton">Esisa Junior Developers Hackaton</Link></li>
            <li>
                <Link class="navcolor" to="/brochureEsisa">
                  Brochure de l'établissement
                </Link>
              </li>
            <li><Link to="/Contactez-nous">Contactez-nous</Link></li>
          </ul>
        </li>
        
        <li><Link to="/PREINSCRIPTION">JE CANDIDATE</Link></li>
        
        <li className="menu-item mg-parent-menu">
          <a>Espaces étudiant</a>
          <ul className="dl-submenu">
            <li><a href="https://classroom.google.com/h">Cours en Ligne</a></li>
            <li><a href="https://esisa.org.ma/login/index.php">Moodle</a></li>
            <li><a href="https://esisa-portail-intranet.vercel.app/intranet">Intranet</a></li>
          </ul>
        </li>
        
        <li className="active">
          <Link to="https://orbite.ma/esisa/">VISITE VIRTUELLE</Link>
        </li>
      </ul>
    </div>
  );
}