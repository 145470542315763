import React from "react";
import NosPartenaires from "../../components/NosPartenaires";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./Workshop.css"

const workshops = [
  {
    year: "2011",
    title: "LA FORMATION AU SERVICE DE L'ENTREPRISE",
    theme: "Thématiques",
    description: "La journée a pour objectif de valoriser les recherches et les réalisations basées sur les technologies numériques et les objets connectés et leurs usages quotidiens.",
    objectives: [
      "Sensibilisation à la nécessité de la bonne formation avant et après le recrutement.",
      "La sensibilisation des institutions d'enseignement supérieur, d'adapter les programmes aux besoins de l'entreprise.",
      "Participation au concours de recrutement et aux formations pour l'insertion.",
      "Participation aux formations des collaborateurs des entreprises actives dans le domaine du développement offshore et autre.",
      "Les formations en alternance.",
      "Créer une synergie entre les chercheurs et les industriels pour la promotion de la recherche et développement."
    ]
  },
  {
    year: "2010",
    title: "LA FORMATION AU SERVICE DU MÉTIER DE L'OFFSHORE",
    theme: "Thématiques",
    description: "La journée vise à réunir un public constitué à la fois d'entreprises multinationales actives dans le domaine du développement offshore, élèves ingénieurs, formateurs experts en technologies de l'information et de la communication.",
    objectives: [
      "La nécessité de la bonne formation avant et après le recrutement.",
      "Sensibilisation des institutions d'enseignement supérieur, d'adapter les programmes aux besoins de l'entreprise.",
      "Participation au concours de recrutement et aux formations pour l'insertion.",
      "Participation aux formations des collaborateurs des entreprises actives dans le domaine du développement offshore."
    ]
  },
  {
    year: "11/2024",
    title: "Application Web & Mobile",
    theme: "Web & Mobile Développement",
    description: "Vous voulez apprendre à créer des Applications Web et Mobile ? Rendez-vous à la salle de conférence pour un Workshop inédit.",
    objectives: [
      "Introduction à la création de sites web modernes.",
      "Apprentissage des bases du HTML, CSS et JavaScript.",
      "Développement d'une application web interactif et responsive en utilisant React Native et Node JS",
      "Apprentissage de l'utilisation de Git pour versionner son projet."
    ],
    date: "2 novembre 2024",
    time: "15h00",
    funnyPhrase: "C'est l'heure d'entreprendre, dans ce Workshop vous apprendrez à créer des sites vitrines !"
  },
  {
    year: "12/2024",
    title: "Transformation Digitale",
    theme: "Technologies & Innovation",
    description: "Apprenez comment les entreprises transforment leur avenir grâce à la technologie numérique.",
    objectives: [
      "Découverte des enjeux de la transformation numérique.",
      "Étude de cas sur les entreprises ayant réussi leur transformation digitale.",
      "Apprentissage des outils et technologies liés à la transformation numérique."
    ],
    date: "30 novembre 2024",
    time: "15h00",
    funnyPhrase: "Le futur est numérique, êtes-vous prêt ?"
  },
  {
    year: "10/2024",
    title: "Création de Site Web / Git",
    theme: "Web & Développement",
    description: "Vous voulez apprendre à créer des sites Web ? Rendez-vous à la salle de conférence pour un Workshop inédit.",
    objectives: [
      "Introduction à Git et GitHub pour la gestion de code.",
      "Apprentissage des bases du développement front-end.",
      "Création d'un site web dynamique avec Git pour versionner les changements."
    ],
    date: "14 décembre 2024",
    time: "10h30",
    funnyPhrase: "C'est l'heure d'entreprendre, dans ce Workshop vous apprendrez à créer des sites vitrines !"
  },
  {
    year: "03/2025",
    title: "Sketchup/ Blender pour Jeux Vidéos 3D",
    theme: "Animation & Modélisation 3D",
    description: "Apprenez la base des animations pour jeux vidéos.",
    objectives: [
      "Introduction à la modélisation 3D avec Sketchup et Blender.",
      "Création d'avatars et d'objets pour des jeux vidéo.",
      "Animation de personnages et objets en 3D.",
      "Réalisation de scènes pour intégrer dans des jeux vidéo."
    ],
    date: "14 mars 2025",
    time: "15h00",
    funnyPhrase: "C'est l'heure de jouer, préparez vos manettes !"
  }
];

export default function Workshop() {
  return (
    <>
      <div className="iq_heading_1 text-left">
        <h4>
          <span className="workshop-heading">WORKSHOPS ORGANISÉS PAR L'ESISA</span>
        </h4>
      </div>

      <div className="workshop-container">
        {workshops.map((workshop, index) => (
          <div className="workshop-card" key={index}>
            <div className="workshop-header">
              <h5 style={{color:"white"}} className="workshop-title">{`WORKSHOP DU ${workshop.year} SOUS LE THÈME : ${workshop.title}`}</h5>
              <h6 style={{color:"whitesmoke"}} className="workshop-subtitle">{workshop.theme}</h6>
            </div>

            <div className="workshop-description">
              <p>{workshop.description}</p>
              <p><strong>{workshop.theme}</strong></p>
              <ul className="workshop-objectives">
                {workshop.objectives.map((objective, idx) => (
                  <li key={idx}>{objective}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      
    </>
  );
}
