import React from "react";
import mailtoLink from "mailto-link";

export default function Contactinfo() {
  const mailto = mailtoLink({
    to: "info@esisa.ac.ma",
    subject: "Demande d'information sur l'ESISA",
    body: "Bonjour, je souhaite obtenir plus d'informations sur l'ESISA."
  });

  const esisaLocation = "https://maps.app.goo.gl/1YudgqkWmvBzbEWj7";

  return (
    <div className="iq_contact_info">
      <div className="container">
        <ul>
          <li>
            <a href={esisaLocation} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-map-marker"></i>
            </a>
            <div className="iq_info_wrap">
              <h5>Adresse</h5>
              <a href={esisaLocation} target="_blank" rel="noopener noreferrer">
                29 bis Av Ibn Khatib Route d'Immouzzer.<span>Fès-Maroc</span>
              </a>
            </div>
          </li>
          <li>
            <a href="tel:+212661453509">
              <i className="fa fa-phone"></i>
            </a>
            <div className="iq_info_wrap">
              <h5>Téléphone</h5>
              <br />
              <a href="tel:+212661453509">+212 (0)6 61 45 35 09</a>
            </div>
          </li>
          <li>
            <a href={mailto} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-envelope"></i>
            </a>
            <div className="iq_info_wrap">
              <h5>Email</h5>
              <br />
              <a href={mailto} target="_blank" rel="noopener noreferrer">
                info@esisa.ac.ma
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}