import React from 'react';
import './SocietesPartenaires.css'; // Assurez-vous d'ajouter un fichier CSS correspondant pour les styles

const SocietesPartenaires = () => {
  return (
<>
    <div class="iq_heading_1 text-left">
    <h4><span style={{fontSize:'25px'}}>PARTENAIRES AU MAROC</span></h4>
</div>  
  
    </>
  );
};

export default SocietesPartenaires;
