import React from "react";

const PolitiqueDeCookies = () => {
  // Styles modernes et épurés
  const titleStyle = {
    fontSize: "3rem", // Taille du titre plus grande pour plus d'impact
    color: "#4cb6ea", // Couleur bleue vibrante
    fontWeight: "600",
    textAlign: "center",
    margin: "2rem 0",
    fontFamily: "'Montserrat', sans-serif",
    letterSpacing: "0.5px",
    textTransform: "uppercase", // Texte en majuscule pour plus de modernité
    animation: "fadeIn 1s ease-out"
  };

  const subTitleStyle = {
    fontSize: "2.2rem",
    color: "#051951", // Gris foncé pour plus de contraste
    fontWeight: "500",
    marginTop: "3rem",
    fontFamily: "'Montserrat', sans-serif",
    letterSpacing: "0.3px",
    textTransform: "uppercase",
    animation: "fadeIn 1.5s ease-out"
  };

  const textStyle = {
    fontSize: "1.1rem",
    lineHeight: "1.7",
    fontFamily: "'Inter', sans-serif", // Typographie moderne
    color: "#333",
    maxWidth: "900px", // Largeur maximale pour la lecture
    margin: "0 auto", // Centrer le texte
    padding: "1.5rem",
    borderRadius: "8px", // Bordure arrondie pour un effet carte
    backgroundColor: "#fff", // Fond blanc pour les sections de texte
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Ombre subtile pour donner de la profondeur
    marginBottom: "2rem", // Espacement sous les sections
    animation: "fadeInUp 1s ease-out"
  };

  const footerStyle = {
    marginTop: "3rem",
    textAlign: "center",
    fontSize: "1.1rem",
    color: "#4cb6ea",
    fontFamily: "'Montserrat', sans-serif",
    padding: "1rem 0",
    borderTop: "2px solid #ddd", // Ligne subtile pour séparer le footer
    backgroundColor: "#f9f9f9",
    letterSpacing: "0.5px",
    animation: "fadeIn 2s ease-out"
  };

  return (
    <div style={{ padding: "3rem", backgroundColor: "#f1f1f1", fontFamily: "'Montserrat', sans-serif" }}>
      <h1 style={titleStyle}>Politique de Gestion des Cookies</h1>
      <p style={textStyle}>
        Bienvenue sur le site officiel de l'ESISA, une institution d'excellence en ingénierie informatique au Maroc. Nous sommes
        engagés à respecter votre vie privée et à vous offrir une transparence totale sur l'utilisation des cookies.
      </p>

      <h2 style={{ fontSize: "25px", padding: "1rem", color: "rgb(75, 182, 234)", fontWeight: "bold" }}>Qu'est-ce qu'un cookie ?</h2>
      <p style={textStyle}>
        Un cookie est un petit fichier texte déposé sur votre appareil (ordinateur, smartphone, tablette) lorsque vous
        visitez un site web. Il permet de collecter et de stocker des informations sur votre navigation afin d'améliorer votre
        expérience utilisateur.
      </p>

      <h2 style={{ fontSize: "25px", padding: "1rem", color: "rgb(75, 182, 234)", fontWeight: "bold" }}>Types de cookies utilisés</h2>
      <p style={textStyle}>
        <li><strong>Cookies Essentiels :</strong> Nécessaires au bon fonctionnement de notre site web.</li>
        <li><strong>Cookies de Performance :</strong> Utilisés pour analyser et améliorer les performances de notre site.</li>
       
      </p>

      <h2 style={{ fontSize: "25px", padding: "1rem", color: "rgb(75, 182, 234)", fontWeight: "bold" }}>Gestion de vos préférences</h2>
      <p style={textStyle}>
        Vous pouvez configurer vos préférences en matière de cookies à tout moment en utilisant les paramètres de votre
        navigateur ou notre outil de gestion des cookies disponible en bas de cette page.
      </p>

      <h2 style={{ fontSize: "25px", padding: "1rem", color: "rgb(75, 182, 234)", fontWeight: "bold" }}>Pourquoi utilisons-nous des cookies ?</h2>
      <p style={textStyle}>
        En tant qu'institution de renommée internationale, nous utilisons des cookies pour :
      </p>
      <p style={textStyle}>
        <li>Assurer la sécurité et le bon fonctionnement de notre site web.</li>
        <li>Offrir une expérience utilisateur optimale et personnalisée.</li>
        <li>Analyser l'utilisation de notre site pour mieux répondre aux attentes des visiteurs.</li>
        <li>Promouvoir nos programmes d'ingénierie avancée et nos collaborations internationales.</li>
      </p>

      <h2  style={{ fontSize: "25px", padding: "1rem", color: "rgb(75, 182, 234)", fontWeight: "bold" }}>Sécurité et confidentialité</h2>
      <p style={textStyle}>
        À l'ESISA, la sécurité de vos données est une priorité absolue. Tous les cookies utilisés sont conformes aux normes
        internationales en matière de protection des données, notamment le RGPD (Règlement Général sur la Protection des
        Données).
      </p>

      <h2 style={{ fontSize: "25px", padding: "1rem", color: "rgb(75, 182, 234)", fontWeight: "bold" }}>Contact</h2>
      <p style={textStyle}>
        Pour toute question ou demande concernant notre politique de cookies, veuillez nous contacter à l'adresse suivante :
      </p>
      <p style={{ ...textStyle, fontWeight: "bold" }}>
  <a href="mailto:info@esisa.ac.ma" style={{ color: "#4cb6ea", textDecoration: "none" }}>
    info@esisa.ac.ma
  </a>
</p>

      <footer style={footerStyle}>
        &copy; 2025 ESISA - Tous droits réservés.
      </footer>
    </div>
  );
};

export default PolitiqueDeCookies;
