import React from "react";
import "./EsisaAnalytica.css"; // Assurez-vous de créer un fichier CSS avec les styles nécessaires
import logolabo from "../../../images/labo/bg.png";

const EsisaAnalytica = () => {
  return (
    <>
      <div>
        <div className="esisa-analytica-container" style={{ display: "flex" }}>
          <div>
            <div className="esisa-analytica-header">
              <h1>LABORATOIRE ESISA ANALYTICA ( LEA )</h1>
              <p>
                Découvrez notre laboratoire de recherche innovant où
                l'Intelligence Artificielle rencontre des domaines tels que
                l'écologie, la finance et la médecine pour transformer les
                solutions de demain.
              </p>
            </div>
            <div className="esisa-analytica-content">
              <p>
                ESISA Analytica est à la pointe de la recherche appliquée,
                collaborant avec des experts pour résoudre des problématiques
                complexes à travers des projets ambitieux dans des domaines
                variés. Avec une équipe de chercheurs spécialisés, nous faisons
                avancer l'innovation dans le monde de l'IA et au-delà.
              </p>
            </div>
            <a
          href="https://esisa-analytica.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="visit-button"
        >
          Visiter notre site
        </a>
          </div>
          <div>
            <img className="logolabo" src={logolabo} />
          </div>
        </div>
     
      </div>
    </>
  );
};

export default EsisaAnalytica;
