import React from 'react';
import { useNavigate } from 'react-router-dom';
import './EsisaCube.css';
import LogoEsisaCube from "./esisacube.png";

const EsisaCube = () => {
  const navigate = useNavigate();

  return (
    <div className="esisa-cube-container">
      <header style={{borderRadius:"15px"}} className="esisa-cube-header">
        <h1 style={{color:"white", fontWeight:"bold"}}>ESISA CUBE</h1>
        <p style={{color:"white", fontWeight:"bold"}}>Votre tremplin vers l'innovation et le succès entrepreneurial</p>
      </header>

      <section className="esisa-cube-intro">
        <div className="esisa-cube-intro-text">
          <h2 style={{ color: "#4cb6ea", fontWeight: "bold", fontSize: "2rem", marginBottom: "2rem" }}>
            Un incubateur dédié à l'Intelligence Artificielle
          </h2>
          <p>
  ESISA CUBE est l'incubateur d'excellence de l'ESISA, dédié à l'accompagnement des startups innovantes dans les domaines de l'Intelligence Artificielle, de la cybersécurité, de la FinTech, de la santé numérique et de la transformation digitale. En tant que hub technologique de référence, ESISA CUBE met à disposition un écosystème ultra-performant intégrant des laboratoires de recherche avancés, un accès exclusif à des infrastructures cloud et de calcul haute performance, ainsi qu'un réseau international de mentors et d'experts issus des plus grandes entreprises et universités mondiales. 

  Grâce à des partenariats stratégiques avec 19 incubateurs de renom et des conventions avec des leaders de l'industrie, ESISA CUBE offre un cadre unique pour transformer les idées novatrices en entreprises à fort impact. Nos startups bénéficient d'un accompagnement sur mesure, d'un accès privilégié aux financements et d'opportunités de collaborations avec des acteurs majeurs du secteur technologique. Rejoignez ESISA CUBE et donnez à votre projet l'impulsion nécessaire pour révolutionner le monde de l'IA et des technologies émergentes.
</p>

        </div>
        <div className="esisa-cube-intro-image">
          <img src={LogoEsisaCube} alt="Incubateur ESISA Cube"  />
        </div>
      </section>

      <section className="esisa-cube-features">
        <h2 style={{ color: "#4cb6ea", fontWeight: "bold", fontSize: "4rem", marginBottom: "2rem" }}>
          Nos Services
        </h2>
        <div className="esisa-cube-feature-card">
          <h3 style={{ color: "#4cb6ea", fontWeight: "bold", fontSize: "2rem", marginBottom: "2rem" }}>
            Accompagnement personnalisé
          </h3>
          <p>
            Bénéficiez de conseils experts, de mentorat et de formations adaptées à vos besoins spécifiques.
          </p>
        </div>
        <div className="esisa-cube-feature-card">
          <h3 style={{ color: "#4cb6ea", fontWeight: "bold", fontSize: "2rem", marginBottom: "2rem" }}>
            Ressources de pointe
          </h3>
          <p>
            Accédez à nos équipements de recherche, nos laboratoires et à un réseau d'experts dans
            l'IA et l'innovation technologique.
          </p>
        </div>
        <div className="esisa-cube-feature-card">
          <h3 style={{ color: "#4cb6ea", fontWeight: "bold", fontSize: "2rem", marginBottom: "2rem" }}>
            Opportunités de financement
          </h3>
          <p>
            Explorez des opportunités de financement grâce à nos partenariats avec des investisseurs
            et des entreprises partenaires.
          </p>
        </div>
      </section>

      <section className="esisa-cube-contact">
        <h2>Rejoignez ESISA CUBE</h2>
        <p>Si vous êtes prêt à faire avancer votre entreprise, contactez-nous pour rejoindre l'incubateur.</p>
        <button 
          className="esisa-cube-cta-button" 
          onClick={() => navigate('/Contactez-nous')}
        >
          Contactez-nous
        </button>
      </section>
    </div>
  );
}

export default EsisaCube;
