import React, { useState } from "react";
import "./Ccna.css";

export default function Ccna() {
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  const handleConfirm = () => {
    window.location.href = "https://esisa.org.ma/login/index.php";
  };

  return (
    <div className="ccna-container">
      <div className="ccna-content">




  <div className="partners-heading" style={{ textAlign: "center" }}>
      <h1 
  className='afterrr' 
  style={{ 
    fontSize: '2.5rem', 
    fontWeight: '700', 
    marginBottom: '1rem', // Réduit pour rapprocher le <hr>
    color: '#4cb6ea' 
  }}
>
FORMATION CCNA
</h1>
<hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
      </div>

        <div className="ccna-description">
          <p>
            Les systèmes et réseaux informatiques font désormais partie intégrante de tous les domaines des technologies de l'information.
          </p>
        </div>

		<h1 
  className='afterrr' 
  style={{ 
    fontSize: '1.5rem', 
    fontWeight: '800', 
    marginBottom: '1rem', // Réduit pour rapprocher le <hr>
    color: '#4cb6ea' 
  }}
>
CONTENU DE LA CERTIFICATION CISCO CCNA
</h1>
        <div className="ccna-curriculum">
          {["CCNA1", "CCNA2", "CCNA3", "CCNA4"].map((course) => (
            <div key={course} className="ccna-course" onClick={() => handleCourseClick(course)}>

			  <h1 
  className='afterrr' 
  style={{ 
    fontSize: '1.5rem', 
    fontWeight: '800', 
    marginBottom: '1rem', // Réduit pour rapprocher le <hr>
    color: '#4cb6ea' 
  }}
>
{course}
</h1>

              <button className="course-btn">Accéder</button>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>Voulez-vous vraiment accéder au cours {selectedCourse} ?</p>
            <div className="modal-buttons">
              <button onClick={handleConfirm} className="btn-confirm">Oui</button>
              <button onClick={() => setShowModal(false)} className="btn-cancel">Non</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}