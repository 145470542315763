import React from 'react';
import NosPartenairesNew from "../International/NosPartenairesNew.js";
import './Objectifs_ESISA.css';

export default function Objectifs_ESISA() {
  return (
    <>
      <div className="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            Objectifs de l'ESISA : Excellence, Innovation, Entrepreneuriat
          </span>
        </h4>
      </div>

      <div className="objectifs-container">
        <div className="objectifs-content">

          <div className="objectifs-details">
            <div className="objectifs-text">
              <p>
                À l'ESISA, nous formons l'élite des ingénieurs en Systèmes d'Information, capables de répondre aux défis technologiques les plus complexes. Notre approche pédagogique allie rigueur académique, immersion pratique et exploration des technologies de demain.
              </p>
              <p>
                Grâce à notre laboratoire de recherche avancé <strong>ESISA ANALYTICA</strong>, nos étudiants se spécialisent dans les domaines de pointe tels que l'Intelligence Artificielle, le Machine Learning et la Cybersécurité. Ce laboratoire est un centre d'innovation où les idées prennent vie et où naissent des solutions révolutionnaires.
              </p>
              <p>
                Nous favorisons également l'esprit entrepreneurial à travers notre incubateur dédié, accompagnant nos étudiants dans la transformation de leurs idées innovantes en entreprises florissantes. Cette synergie entre la recherche, l'innovation et l'entrepreneuriat fait de l'ESISA un acteur majeur de la transformation numérique au niveau national et international.
              </p>
            </div>
          </div>

          <h5 className="objectifs-subheading">UNE EXPERTISE MULTIDIMENSIONNELLE :</h5>
          <div className="objectifs-text">
            <p>
              Nos ingénieurs maîtrisent non seulement la programmation avancée, mais aussi l'architecture des systèmes complexes et la gestion des projets informatiques. Ils apprennent à anticiper les évolutions technologiques et à concevoir des solutions durables et performantes.
            </p>
          </div>

          <h5 className="objectifs-subheading">INNOVER AVEC ESISA ANALYTICA :</h5>
          <div className="objectifs-text">
            <p>
              En intégrant <strong>ESISA ANALYTICA</strong>, nos étudiants accèdent à des infrastructures de pointe, collaborent avec des chercheurs et développent des solutions en Intelligence Artificielle qui répondent aux besoins industriels et sociétaux actuels.
            </p>
          </div>

          <h5 className="objectifs-subheading">DÉVELOPPER L'ESPRIT ENTREPRENEURIAL :</h5>
          <div className="objectifs-text">
            <p>
              Notre incubateur soutient les projets novateurs en offrant un accompagnement personnalisé, des ressources techniques et un accès à un vaste réseau de partenaires industriels. Nous formons des ingénieurs visionnaires, prêts à devenir les leaders technologiques de demain.
            </p>
          </div>

          <img style={{ marginTop: "2rem" }} src="images/images/president2.png" alt="Président" className="objectifs-image" />
        </div>

        <NosPartenairesNew />
      </div>
    </>
  );
}