import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function dijon() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/unvbou1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' , width:"60%"}} 
                />
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'UNIVERSITÉ DE BOURGOGNE <Rentree />
            </a>
                  <br/> <br/>

            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENNOUNA YAHYA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Bases de Données et Intelligence Artificielle</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL FOUGHALI YASSINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M1 Bases de Données et Intelligence Artificielle</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                MALIH TAHA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Santé et Intelligence Artificielle</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                TIMMOUCH SOUKAINA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M1 Bases de Données et Intelligence Artificielle</span>
            </p>

            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ZOLATI CHAIMAE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Santé et Intelligence Artificielle</span>
            </p>

            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ROUNEB CHAIMAE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Bases de Données et Intelligence Artificielle</span>
            </p>
        </div>
    )
}
