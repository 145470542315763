import React from "react";
import NosPartenaires from "../../components/NosPartenaires";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./Procedure_admission.css"; // Ajout du CSS moderne

export default function ProcedureAdmission() {
  return (
    <div className="admission-container">
      <div className="admission-content">
        {/* Section Titre */}
        <div className="admission-header">
          <h2 style={{fontWeight:"bold", color:"#4cb6ea"}}>🎓 Parcours et Procédure d'Admission</h2>
          <hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
          <p className="admission-subtitle">
            L'ESISA sélectionne les meilleurs talents pour former l'élite en ingénierie informatique.
          </p>
        </div>

        {/* Section Processus d'Admission */}
        <div className="admission-process">
          <p>
            À l'ESISA, nous croyons que <strong>la motivation</strong> et <strong>la passion pour le numérique</strong> sont les moteurs de l'excellence.
            Nous recherchons des étudiants déterminés, prêts à façonner le monde de demain grâce aux technologies innovantes.
            Chaque candidature est évaluée avec soin en tenant compte de :
          </p>

          <ul className="admission-criteria">
            <li>📌 <strong>Motivation et Ambition :</strong> Votre détermination à réussir et à innover est essentielle.</li>
            <li>📌 <strong>Note en Mathématiques et Français au Bac :</strong> Indicateurs clés de réussite.</li>
            <li>📌 <strong>Si les notes sont insuffisantes :</strong> Un test de Mathématiques est requis.</li>
            <li>📌 <strong>Entretien de motivation :</strong> Pour évaluer votre passion, vos objectifs et votre vision.</li>
          </ul>

          <p>
            Nous privilégions les profils <strong>engagés</strong> et <strong>motivés</strong>, capables de relever les défis technologiques de demain.
          </p>
        </div>

        {/* Galerie d'Images */}
        <div className="admission-gallery">
          <Gallery>
            <Item original="images/final/final.png" thumbnail="images/final/final.png" width="750" height="1000">
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src="images/final/final.png" alt="Campus ESISA" className="admission-image"/>
              )}
            </Item>
          </Gallery>
        </div>
      </div>
    </div>
  );
}
