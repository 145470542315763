import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './AdmisEtranger.css'; // Importer les styles CSS

export default function AdmisEtranger() {

  const partenaires = [
		{ href: "https://www.ensiie.fr/", src: "images/partenaire/ensiie1.png" },
		{ href: "https://polytech.univ-amu.fr/", src: "images/partenaire/poly1.png" },
		{ href: "https://www.eilco-ulco.fr/", src: "images/partenaire/cote1.png" },
		{ href: "https://www.isima.fr/", src: "images/partenaire/isima1.png" },
		{ href: "https://www.univ-lyon2.fr/", src: "images/partenaire/lyon1.png" },
    { href: "https://www.univ-amu.fr/", src: "images/partenaire/amu.png" },
		{ href: "https://www.univ-lorraine.fr/", src: "images/partenaire/lor1.png" },
		{ href: "https://www.univ-paris8.fr/", src: "images/partenaire/paris81.png" },
		{ href: "https://www.u-bourgogne.fr/", src: "images/partenaire/unvbou1.png" },
		{ href: "https://www.univ-littoral.fr/", src: "images/partenaire/unvop1.png" },
		{ href: "https://www.uca.fr/", src: "images/partenaire/uca1.png" },
		{ href: "https://www.univ-paris13.fr/", src: "images/partenaire/paris131.png" },
		{ href: "https://www.univ-lille.fr/", src: "images/partenaire/lille1.png" },
		{ href: "https://www.lis-lab.fr/", src: "images/partenaire/lis1.png" },
    { href: "https://univ-cotedazur.fr/", src: "images/partenaire/cotedazur.png" },
		{ href: "https://www.iscid-co.fr/", src: "images/partenaire/iscid.png" },

	  ];

  return (
    <>
      <div className="iq_heading_1 text-left">
        <h4>
          <span style={{ fontSize: "25px", textAlign: "center" }}>
            {" "}
            ADMIS À L'ÉTRANGER
          </span>
        </h4>
      </div>

      <div className="admis-container">
        <div className="admis-content">
          <p className="admis-description">
            Chaque année, une grande partie des étudiants de la 4ème année continuent leurs études en France, soit avec nos partenaires, soit dans d'autres universités étrangères.
            L'année universitaire 2024/2025, 95% des étudiants ont été acceptés à nos universités et ecole d'ingenieurs partenaires présents ci-dessous.
          </p>
          <table className="admis-table">
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {[...Array(4)].map((_, colIndex) => {
                    const year = 2002 + rowIndex * 5 + colIndex;
                    const isDisabled = year >= 2024;

                    return (
                     <div></div>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
<div className="widget partners-section">
      <div className="partners-heading" style={{ textAlign: "center" }}>
        <h5 style={{ color: "black", fontWeight:"800" , marginBottom:"2rem"}}>NOS PARTENAIRES EN FRANCE</h5>
      </div>
      <div className="partners-thumbnail"></div>
      <div className="partners-gallery">
        {partenaires.map((partenaire, index) => (
          <div
            key={index}
            className="partner-item"
            style={{ textAlign: "center" }}
          >
            <a
              href={partenaire.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="partner-image"
                src={partenaire.src}
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </a>
          </div>
        ))}
      </div>
    </div>      </div>
    </>
  );
}
