


import React from "react";
import NosPartenaires from "../../components/NosPartenaires";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./Workshop.css";

const workshops = [
  {
    year: "2016",
    title: "Journée nationale organisée le 29 Novembre 2016 à la Cité de l'Innovation, Nokia Paris-Saclay",
    theme: "Thématiques",
    description: "La journée a pour objectif de valoriser les recherches et les réalisations basées sur les technologies numériques et les objets connectés et leurs usages quotidiens.",
    objectives: [
      "Sensibilisation à la nécessité de la bonne formation avant et après le recrutement.",
      "La sensibilisation des institutions d'enseignement supérieur, d'adapter les programmes aux besoins de l'entreprise.",
      "Participation au concours de recrutement et aux formations pour l'insertion.",
      "Participation aux formations des collaborateurs des entreprises actives dans le domaine du développement offshore et autre.",
      "Les formations en alternance.",
      "Créer une synergie entre les chercheurs et les industriels pour la promotion de la recherche et développement."
    ],
    images: [
      "images/images4/3(1).jpg",
      "images/images4/3(2).jpg"
    ]
  },
  {
    year: "2010",
    title: "WORKSHOP DU 12 MARS 2010 SOUS LE THÈME : LA FORMATION AU SERVICE DU MÉTIER DE L'OFFSHORE",
    theme: "Thématiques",
    description: "La journée vise à réunir un public constitué à la fois d'entreprises multinationales actives dans le domaine du développement offshore, élèves ingénieurs, formateurs experts en technologies de l'information et de la communication.",
    objectives: [
      "La nécessité de la bonne formation avant et après le recrutement.",
      "Sensibilisation des institutions d'enseignement supérieur, d'adapter les programmes aux besoins de l'entreprise.",
      "Participation au concours de recrutement et aux formations pour l'insertion.",
      "Participation aux formations des collaborateurs des entreprises actives dans le domaine du développement offshore."
    ],
    images: [
      "images/images4/stt3.gif"
    ]
  },
  {
    year: "2007",
    title: "Sciences of Electronics, Technologies of Information and Telecommunication, Hammamet 2007",
    theme: "Thématiques",
    description: "Cette conférence a réuni des leaders académiques et industriels pour discuter des derniers progrès dans le domaine des technologies de l'information.",
    objectives: [
      "Rassembler les chercheurs et les professionnels du secteur.",
      "Partager les avancées récentes en matière de télécommunications.",
      "Discuter des nouvelles applications dans l'industrie et le secteur public."
    ],
    images: [
      "images/images4/stt2.gif"
    ]
  },
  {
    year: "2005",
    title: "THE FIRST INTERNATIONAL CONFERENCE ON DISTRIBUTED FRAMEWORKS FOR MULTIMEDIA APPLICATION DFMA 2005 - FEBRUARY 6-9, 2005 - BESANÇON, FRANCE",
    theme: "Thématiques",
    description: "Cette conférence a mis en avant les défis technologiques rencontrés dans le cadre de la gestion des applications multimédia distribuées.",
    objectives: [
      "Analyser les défis rencontrés dans les applications multimédia.",
      "Explorer les solutions en matière de frameworks distribués.",
      "Partager des études de cas sur les applications distribuées à grande échelle."
    ],
    images: []
  }
];

export default function Conferences_etranger() {
  return (
    <>
      <div className="iq_heading_1 text-left">
        <h4>
          <span className="workshop-heading">CONFÉRENCES À L'ÉTRANGER</span>
        </h4>
      </div>

      <div className="workshop-container">
        {workshops.map((workshop, index) => (
          <div className="workshop-card" key={index}>
            <div className="workshop-header">
              <h5 style={{color:"white"}} className="workshop-title">{`${workshop.title}`}</h5>
              <h6 style={{color:"whitesmoke"}} className="workshop-subtitle">{workshop.theme}</h6>
            </div>

            <div className="workshop-description">
              <p>{workshop.description}</p>
              <p><strong>{workshop.theme}</strong></p>
              <ul className="workshop-objectives">
                {workshop.objectives.map((objective, idx) => (
                  <li key={idx}>{objective}</li>
                ))}
              </ul>
            </div>

            {/* {workshop.images.length > 0 && (
              <Gallery>
                {workshop.images.map((img, idx) => (
                  <Item key={idx} original={img} thumbnail={img} width="500" height="500">
                    {({ ref, open }) => <img ref={ref} onClick={open} src={img} alt="Conference" />}
                  </Item>
                ))}
              </Gallery>
            )} */}
          </div>
        ))}
      </div>

    </>
  );
}
