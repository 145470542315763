import React, { useState } from "react";
import { TextField, Button, Box, Container, Typography, Grid, LinearProgress, Checkbox, FormControlLabel, FormHelperText, Tooltip } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react'; 
import logo from "./logo.png"; 
import "./HackatonInscription.css";

const HackatonInscription = () => {
  

  const [participant, setParticipant] = useState({ 
    nom: '', prenom: '', dateNaissance: '', lieuNaissance: '', ville: '', number:'', lycee: '', otherVille: '', otherLycee: '' 
  });
  const [rgpdChecked, setRgpdChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [errors, setErrors] = useState({});

  // Données des lycées par ville
  const lyceesByVille = {
    Fes: [
        { nom: "Lycée IPEP Fès", code: "ipep" },
        { nom: "ESL", code: "esl" },
        { nom: "Lycée Jabr", code: "jabr" },
        { nom: "Lycée Moulay Youssef", code: "moulay-youssef" },
        { nom: "Lycée Hassan II", code: "hassan-ii" },
        { nom: "Lycée Ibn Al Haytham", code: "ibn-al-haytham" },
        { nom: "Lycée Descartes", code: "descartes" },
        { nom: "Lycée Ibn al Athir", code: "ibn-al-athir" },
        { nom: "Lycée Ibn Hanbal", code: "ibn-hanbal" },
        { nom: "Lycée Abdelkrim Raiss", code: "abdelkrim-raiss" },
        { nom: "Fès City Lycée", code: "fes-city" },
        { nom: "Lycée ONESIGMA", code: "onesigma" },
        { nom: "Lycée Ibn Rochd", code: "ibn-rochd" },
        { nom: "Lycée Qualifiant Al Adarissa", code: "al-adarissa" },
        { nom: "Lycée Technique", code: "lycee-technique" },
        { nom: "Lycée Ibn Hazm", code: "ibn-hazm" },
        { nom: "Lycée Abdellah Laaroui", code: "abdellah-laaroui" },
        { nom: "Lycée Moulay Idriss", code: "moulay-idriss" },
        { nom: "Lycée Moulay Slimane", code: "moulay-slimane" },
        { nom: "Lycée Ibn Al Arabi", code: "ibn-al-arabi" },
        { nom: "Lycée Abdelkhalek Torres", code: "abdelkhalek-torres" },
        { nom: "Lycée Tarik Ibn Ziad", code: "tarik-ibn-ziad" },
        { nom: "Groupe Scolaire Louis Le Grand Fès", code: "louis-le-grand" },
        { nom: "Groupe Scolaire Universel La Fontaine", code: "gsu-la-fontaine" },
        { nom: "École Groupe Scolaire Washington de Fès", code: "washington-fes" }
    ],
    Casablanca: [
        { nom: "Lycée Lyautey", code: "lyautey" },
        { nom: "Lycée Jean de La Fontaine", code: "jean-de-la-fontaine" },
        { nom: "Lycée Al Jabr", code: "al-jabr" }
    ],
    Marrakech: [
        { nom: "Lycée Abdelmoumen", code: "abdelmoumen" },
        { nom: "Lycée Moulay Rachid", code: "moulay-rachid" }
    ],
    Tangier: [
        { nom: "Lycée Ibn Tofail", code: "ibn-tofail" },
        { nom: "Lycée Al Amal", code: "al-amal" }
    ],
    Rabat: [
        { nom: "Lycée Rabat Al Maarif", code: "rabat-al-maarif" },
        { nom: "Lycée Descartes", code: "descartes-rabat" }
    ],
    Meknes: [
      { nom: "Lycée Paul Valery", code: "paul-valery" },
      { nom: "Lycée Moulay Ismail", code: "moulay-ismail" },
      { nom: "Lycée Lalla Amina", code: "lalla-amina" },
      { nom: "Lycée Ibn Al Baytar", code: "ibn-al-baytar" },
      { nom: "Lycée Omar Ibn Al Khattab", code: "omar-ibn-al-khattab" },
      { nom: "Lycée Badr", code: "badr" },
      { nom: "Lycée Kasbah", code: "kasbah" },
      { nom: "Collège Lycée MANHAL AL MAARIFA", code: "manhal-al-maarifa" },
      { nom: "Lycée Zouini 3", code: "zouini-3" },
      { nom: "Lycée préparatoire Bin Hamdoun Al Kartili", code: "bin-hamdoun-al-kartili" },
      { nom: "Lycée Zitoune", code: "zitoune" },
      { nom: "Lycée Privé Label Excellence", code: "label-excellence" },
      { nom: "Lycée Privé La Préférence", code: "la-belle-preference" },
      { nom: "Lycée Privé La référence", code: "la-belle-preference" },
      { nom: "Lycée Privé Al Jabr Meknès", code: "al-jabr-meknes" },
      { nom: "Lycée Privé El Bachir", code: "el-bachir" },
      { nom: "Lycée Privé Les Génies", code: "les-genies" },
      { nom: "Lycée Privé Excellence Meknès", code: "excellence-meknes" }
  ]
};


  const resetForm = () => {
    setParticipant({ nom: '', prenom: '', dateNaissance: '', lieuNaissance: '', ville: '', email: '',number:'', lycee: '', otherVille: '', otherLycee: '' });
    setRgpdChecked(false);
    setShowQRCode(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation simple
    const newErrors = {};
    if (!participant.nom) newErrors.nom = "Nom requis";
    if (!participant.prenom) newErrors.prenom = "Prénom requis";
    if (!participant.email || !/\S+@\S+\.\S+/.test(participant.email)) newErrors.email = "Email invalide";
    if (!participant.dateNaissance) newErrors.dateNaissance = "Date de naissance requise";
    if (!participant.lieuNaissance) newErrors.lieuNaissance = "Lieu de naissance requis";
    if (!participant.ville) newErrors.ville = "Ville requise";
    if (!participant.number) newErrors.number = "Numéro requis";

    
    if (!participant.lycee && participant.ville !== 'Autres') newErrors.lycee = "Lycée requis";
    if (!rgpdChecked) newErrors.rgpd = "Vous devez accepter la politique CNDP";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await axios.post(
          'https://soiree-inte-back.vercel.app/send-email-hackathon',
          { participant }
        );
        if (response.status === 200) {
          toast.success('Inscription réussie ! Un email de confirmation a été envoyé.');
          setShowQRCode(true);
          resetForm();
        } else {
          toast.error('Échec de l\'inscription.');
        }
      } catch (error) {
        console.error('Erreur :', error);
        toast.error('Erreur lors de l\'inscription.');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleVilleChange = (e) => {
    const selectedVille = e.target.value;
    setParticipant({ ...participant, ville: selectedVille, lycee: '', otherVille: selectedVille === 'Autres' ? '' : participant.otherVille });
  };

  const handleLyceeChange = (e) => {
    const selectedLycee = e.target.value;
    setParticipant({ ...participant, lycee: selectedLycee, otherLycee: selectedLycee === 'Autres' ? '' : participant.otherLycee });
  };

  return (
    <>
      <div className="centered-content">
        <img className="logo" src={logo} alt="logo" />
        <h2 style={{ color: "#4cb6ea", fontWeight: "bold", textAlign: "center" }} className="title"> Inscription pour Junior Developers Hackaton</h2>
      </div>

      <Container maxWidth="sm">
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <form onSubmit={handleFormSubmit}>
            <Typography variant="h6" gutterBottom>
              Informations du participant
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nom"
                  variant="outlined"
                  fullWidth
                  value={participant.nom}
                  onChange={(e) => setParticipant({ ...participant, nom: e.target.value })}
                  error={!!errors.nom}
                  helperText={errors.nom}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Prénom"
                  variant="outlined"
                  fullWidth
                  value={participant.prenom}
                  onChange={(e) => setParticipant({ ...participant, prenom: e.target.value })}
                  error={!!errors.prenom}
                  helperText={errors.prenom}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={participant.email}
                  onChange={(e) => setParticipant({ ...participant, email: e.target.value })}
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Numéro de Telephone"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={participant.number}
                  onChange={(e) => setParticipant({ ...participant, number: e.target.value })}
                  error={!!errors.number}
                  helperText={errors.number}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="date"
                  label="Date de naissance"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={participant.dateNaissance}
                  onChange={(e) => setParticipant({ ...participant, dateNaissance: e.target.value })}
                  error={!!errors.dateNaissance}
                  helperText={errors.dateNaissance}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Lieu de naissance"
                  variant="outlined"
                  fullWidth
                  value={participant.lieuNaissance}
                  onChange={(e) => setParticipant({ ...participant, lieuNaissance: e.target.value })}
                  error={!!errors.lieuNaissance}
                  helperText={errors.lieuNaissance}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  value={participant.ville}
                  onChange={handleVilleChange}
                  error={!!errors.ville}
                  required
                  SelectProps={{ native: true }}
                >
                  <option value="">Sélectionnez une ville</option>
                  {Object.keys(lyceesByVille).map((ville) => (
                    <option key={ville} value={ville}>
                      {ville}
                    </option>
                  ))}
                  <option value="Autres">Autres</option>
                </TextField>
                {errors.ville && <FormHelperText error>{errors.ville}</FormHelperText>}
              </Grid>

              {participant.ville === 'Autres' && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={participant.otherVille}
                    onChange={(e) => setParticipant({ ...participant, otherVille: e.target.value })}
                    error={!!errors.otherVille}
                    helperText={errors.otherVille}
                    required
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  value={participant.lycee}
                  onChange={handleLyceeChange}
                  error={!!errors.lycee}
                  required={participant.ville !== 'Autres'}
                  SelectProps={{ native: true }}
                >
                  <option value="">Sélectionnez un lycée</option>
                  {lyceesByVille[participant.ville]?.map((lycee) => (
                    <option key={lycee.code} value={lycee.nom}>
                      {lycee.nom}
                    </option>
                  ))}
                  <option value="Autres">Autres</option>
                </TextField>
                {errors.lycee && <FormHelperText error>{errors.lycee}</FormHelperText>}
              </Grid>

              {participant.lycee === 'Autres' && (
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={participant.otherLycee}
                    onChange={(e) => setParticipant({ ...participant, otherLycee: e.target.value })}
                    error={!!errors.otherLycee}
                    helperText={errors.otherLycee}
                    required
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rgpdChecked}
                      onChange={(e) => setRgpdChecked(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Je consens à la collecte et à l'utilisation de mes informations dans le respect de la politique CNDP."
                />
                {errors.rgpd && <FormHelperText error>{errors.rgpd}</FormHelperText>}
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth disabled={loading}>
                  {loading ? "Enregistrement..." : "S'inscrire"}
                </Button>
              </Grid>
            </Grid>
          </form>

          {showQRCode && (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <QRCodeSVG value="Inscription confirmée" size={128} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Votre inscription a été confirmée. Scannez le QR Code pour plus de détails !
              </Typography>
            </Box>
          )}
        </Box>
      </Container>

      <ToastContainer />
    </>
  );
};

export default HackatonInscription;
