import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaPaperPlane, FaDownload, FaGraduationCap, FaRobot, FaBrain, FaCode } from 'react-icons/fa';
import { motion, useScroll, useTransform } from 'framer-motion';
import reconnu from './reconnu3.png';
import './Bac5IS2IA.css';

export default function Bac5IS2IA() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.2], [1, 0.8]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCandidaterClick = () => {
    navigate('/PREINSCRIPTION');
  };

  const handleDownloadClick = () => {
    window.open('images/final/IS2IA.pdf', '_blank');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  return (
    <motion.div 
      className="master-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <img src={reconnu} alt="Formation Reconnue" className="recognition-badge" />
      <motion.div 
        className="master-header" 
        variants={itemVariants}
        style={{ opacity, scale }}
      >
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <FaRobot size={60} color="#4cb6ea" />
        </motion.div>
        <h1 className="master-title">
          INGÉNIERIE DES SYSTÈMES D'INFORMATION APPLIQUÉE À L'INTELLIGENCE ARTIFICIELLE (IS2IA)
        </h1>
        <h2 className="master-subtitle">Master en Ingénierie des Systèmes d'Information & Intelligence Artificielle</h2>
      </motion.div>

      <motion.div className="master-content" variants={itemVariants}>
        <motion.p className="program-description" variants={itemVariants}>
          Formation d'excellence reconnue par l'État, notre Master en Intelligence Artificielle et Systèmes Intelligents (IS2IA) est un diplôme prestigieux qui forme les experts de demain en intelligence artificielle. Cette formation innovante de deux ans combine théorie avancée et applications pratiques, permettant aux étudiants de maîtriser les technologies d'IA, le deep learning et le machine learning, tout en développant une expertise en recherche et développement. Le programme est disponible en formation initiale ou en alternance, offrant une expérience professionnelle enrichissante avec 2 jours en entreprise et 3 jours à l'école.
        </motion.p>

        <motion.div className="program-features" variants={itemVariants}>
          <div className="feature-grid">
            <div className="feature-item">
              <FaGraduationCap size={30} color="#4cb6ea" />
              <h4>Formation en Alternance</h4>
              <p>2 jours en entreprise (Lundi-Mardi) et 3 jours à l'école (Mercredi-Vendredi) pour une expérience professionnelle enrichissante</p>
            </div>
            <div className="feature-item">
              <FaCode size={30} color="#4cb6ea" />
              <h4>Expertise IA</h4>
              <p>Maîtrise du Deep Learning, Machine Learning et des technologies d'Intelligence Artificielle</p>
            </div>
            <div className="feature-item">
              <FaBrain size={30} color="#4cb6ea" />
              <h4>Recherche & Innovation</h4>
              <p>Développement de projets innovants et participation à des recherches avancées</p>
            </div>
          </div>
        </motion.div>

        <motion.div className="program-opportunities" variants={itemVariants}>
          <h3 className="opportunities-title">Débouchés d'Excellence</h3>
          <div className="opportunities-grid">
            <div className="opportunity-block">
              <h4>Carrières Prometteuses</h4>
              <ul>
                <li>Expert en Intelligence Artificielle</li>
                <li>Data Scientist Senior</li>
                <li>Chef de Projet IA</li>
                <li>Chercheur en IA</li>
                <li>Architecte de Solutions IA</li>
              </ul>
            </div>
            <div className="opportunity-block">
              <h4>Expertise Acquise</h4>
              <ul>
                <li>Deep Learning et Réseaux de Neurones</li>
                <li>Machine Learning Avancé</li>
                <li>Traitement du Langage Naturel</li>
                <li>Vision par Ordinateur</li>
                <li>Big Data et Analytics</li>
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <FaCode size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">1er Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISIIA S1.1 - Ingénierie de l'Objet et Design Patterns</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISHIA S1.2 - Ingénierie du Web et Technologie XML</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S1.3 - Intelligence Artificielle (Machine learning)</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S1.4 - Bases de données Avancées</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISHIA S1.5 - Cloud, IOT et Sécurité</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S1.6 - Culture et Communication</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S1.7 - Power Skills (Développement personnel)</motion.li>
          </ul>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <FaBrain size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">2ème Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISIIA S2.1 - Technologie Dot Net</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S2.2 - Architecture des Applications Distribuées</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIA S2.3 - Data Analysis & Data Mining</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISTIA S2.4 - Méthodologie de Projet</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S2.5 - Programmation Mobile</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISHIA S2.6 - Intelligence Artificielle (Deep learning)</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S2.7 - CULTURE ET COMMUNICATION 2</motion.li>
          </ul>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <FaGraduationCap size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">3ème Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISTIA S3.1 - Entreprenariat & Gestion de Projet</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S3.2 - Urbanisation des Systèmes d'Information et WorkFlow</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S3.3 - Sécurité des Systèmes d'Information</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S3.4 - Applications sur l'IA: Renforcement, Fédération et Génération</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISTIA S3.5 - Big Data et Eco Système Hadoop</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISTIA S3.6 - Projet de Synthèse</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISIIA S3.7 - Culture et Communication</motion.li>
          </ul>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <FaRobot size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">4ème Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISIA S4 - Préparation au marché de l'emploi</motion.li>
          </ul>
        </motion.div>

        <motion.div 
          className="action-buttons" 
          variants={itemVariants}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.7 }}
        >
          <Button
            variant="primary"
            className="btn btn-primary"
            onClick={handleCandidaterClick}
          >
            <FaPaperPlane />
            Candidater
          </Button>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleDownloadClick}
          >
            <FaDownload />
            Télécharger la brochure
          </Button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
