import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function polytech() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/poly1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%', width:"80%" }} 
                />
            </div>
            <br /> 
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Ecole Polytechnique Universitaire de Marseille <Rentree />
            </a>
            <br /> <br />
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AABARI ISMAIL - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>4ème année Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ASBAGUI LINA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>4ème année Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BELHAJ IKRAM - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>4ème année Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                CHAOUI MARWA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>4ème année Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                TAGMOUTI GHITA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>4ème année Génie Industriel et Informatique</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BENDALI YASSINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>4ème année Génie Informatique</span>
            </p>
        </div>
    )
}
