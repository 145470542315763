import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaPaperPlane, FaGraduationCap, FaCode, FaBrain, FaLaptopCode } from 'react-icons/fa';
import { motion, useScroll, useTransform } from 'framer-motion';
import reconnu from './reconnu3.png';
import './Bac3.css';

export default function Bac3() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.2], [1, 0.8]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCandidaterClick = () => {
    navigate('/PREINSCRIPTION');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  return (
    <motion.div 
      className="program-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <img src={reconnu} alt="Formation Reconnue" className="recognition-badge" />
      <motion.div 
        className="program-header" 
        variants={itemVariants}
        style={{ opacity, scale }}
      >
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <FaGraduationCap size={60} color="#4cb6ea" />
        </motion.div>
        <h1 className="program-title">
          LICENCE EN INGÉNIERIE DES SYSTÈMES D'INFORMATION (BAC+3)
        </h1>
        <h2 className="program-subtitle">Formation en Ingénierie Logicielle</h2>
      </motion.div>

      <motion.div className="program-content" variants={itemVariants}>
        <motion.p className="program-description" variants={itemVariants}>
          Formation d'excellence reconnue par l'État, notre Licence en Ingénierie des Systèmes d'Information (BAC+3) est un diplôme prestigieux qui ouvre les portes vers des carrières prometteuses dans le domaine de l'informatique. Cette formation rigoureuse de trois ans combine une solide formation théorique avec des projets pratiques innovants, permettant aux étudiants de développer des compétences techniques et professionnelles de haut niveau.
        </motion.p>

        <motion.div className="program-features" variants={itemVariants}>
          <div className="feature-grid">
            <div className="feature-item">
              <FaGraduationCap size={30} color="#4cb6ea" />
              <h4>Diplôme d'Excellence</h4>
              <p>Formation reconnue par l'État avec un taux de réussite exceptionnel</p>
            </div>
            <div className="feature-item">
              <FaCode size={30} color="#4cb6ea" />
              <h4>Programme Innovant</h4>
              <p>Curriculum moderne adapté aux besoins du marché</p>
            </div>
            <div className="feature-item">
              <FaBrain size={30} color="#4cb6ea" />
              <h4>Pédagogie Avancée</h4>
              <p>Méthodes d'apprentissage modernes et interactives</p>
            </div>
          </div>
        </motion.div>

        <motion.div className="program-opportunities" variants={itemVariants}>
          <h3 className="opportunities-title">Débouchés d'Excellence</h3>
          <div className="opportunities-grid">
            <div className="opportunity-block">
              <h4>Poursuite d'Études</h4>
              <ul>
                <li>Master IS2IA (Intelligence Artificielle)</li>
                <li>Master ISITN (Systèmes d'Information)</li>
                <li>Admission parallèle dans d'autres filières universitaires</li>
                <li>Intégration possible en écoles d'ingénieurs</li>
              </ul>
            </div>
            <div className="opportunity-block">
              <h4>Carrières Prometteuses</h4>
              <ul>
                <li>Développeur Full-Stack Senior</li>
                <li>Architecte Logiciel</li>
                <li>Chef de Projet IT</li>
                <li>Expert en Cybersécurité</li>
                <li>Consultant en Transformation Digitale</li>
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <FaCode size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">1ère Année</h3>
          <div className="semester-grid">
            <div className="semester-block">
              <h4 className="semester-subtitle">1er Semestre</h4>
              <ul className="course-list">
                <motion.li className="course-item" variants={itemVariants}>IL - S1.1 - Algorithmique I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S1.2 - Programmation C I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S1.3 - Architecture et Système d'Exploitation I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S1.4 - Electronique Numérique I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S1.5 - Mathématiques Analyse I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S1.6 - TEC & Anglais I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S1.7 - Méthodologie De Travail Universitaire</motion.li>
              </ul>
            </div>
            <div className="semester-block">
              <h4 className="semester-subtitle">2ème Semestre</h4>
              <ul className="course-list">
                <motion.li className="course-item" variants={itemVariants}>IL - S2.1 - Algorithmique II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S2.2 - Programmation C II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S2.3 - Architecture et Système d'Exploitation II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S2.4 - Electronique Numérique II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S2.5 - Mathématiques Algèbre & Statistique Descriptive</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S2.6 - Culture digitale et bureautique</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S2.7 - TEC & Anglais II</motion.li>
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <FaBrain size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">2ème Année</h3>
          <div className="semester-grid">
            <div className="semester-block">
              <h4 className="semester-subtitle">3ème Semestre</h4>
              <ul className="course-list">
                <motion.li className="course-item" variants={itemVariants}>IL - S3.1 - Algorithmique - Structures de Données I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S3.2 - Programmation Structurée en C++ & Système Unix</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S3.3 - Introduction aux Systèmes Embarqués & Traitement Numérique de Signal</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S3.4 - Culture Marocaine et Économie Générale</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S3.5 - Mathématiques Analyse II & Statistique Inférentielle I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S3.6 - Bases de données I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S3.7 - TEC & Anglais III</motion.li>
              </ul>
            </div>
            <div className="semester-block">
              <h4 className="semester-subtitle">4ème Semestre</h4>
              <ul className="course-list">
                <motion.li className="course-item" variants={itemVariants}>IL - S4.1 - Algorithmique - Structures de Données II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S4.2 - Programmation Structurée en C++ II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S4.3 - Programmation en Python</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S4.4 - Analyse Numérique et Statistique Inférentielle II</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S4.5 - Bases de Données II & Introduction aux Technologies du Web</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S4.6 - Développement personnel</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S4.7 - TEC & Anglais IV</motion.li>
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <FaLaptopCode size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">3ème Année</h3>
          <div className="semester-grid">
            <div className="semester-block">
              <h4 className="semester-subtitle">5ème Semestre</h4>
              <ul className="course-list">
                <motion.li className="course-item" variants={itemVariants}>IL - S5.1 - Recherche Opérationnelle et Théorie des Graphes</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S5.2 - Systèmes d'Information</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S5.3 - Culture d'Entrepreneuriat</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S5.4 - Programmation Orientée Objet en Java et C#</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S5.5 - Système d'Exploitation & Python Avancé</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S5.6 - Communication Professionnelle I</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S5.7 - Analyse de Données I et Analyse Numérique II</motion.li>
              </ul>
            </div>
            <div className="semester-block">
              <h4 className="semester-subtitle">6ème Semestre</h4>
              <ul className="course-list">
                <motion.li className="course-item" variants={itemVariants}>IL - S6.1 - Langage UML & Développement Web</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S6.2 - Programmation Java Avancée et Dot Net</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S6.3 - Interconnexion des Réseaux</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S6.4 - Analyse de Données II & Business Intelligence</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S6.5 - Droit, Civisme et Citoyenneté</motion.li>
                <motion.li className="course-item" variants={itemVariants}>IL - S6.6 - Communication Professionnelle II & Stage (2-3 mois) en Entreprise</motion.li>
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div 
          className="action-buttons" 
          variants={itemVariants}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <Button
            variant="primary"
            className="btn btn-primary"
            onClick={handleCandidaterClick}
          >
            <FaPaperPlane />
            Candidater
          </Button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
