import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "./slider.css";
import image2 from "../../images/new/image2.png";
import image3 from "../../images/new/hackadton.png";

const PauseOnHover = () => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    appendDots: (dots) => (
      <div
        style={{
          width: "100%",
          bottom: "10px",
          height: "auto",
          position: "absolute",
          textAlign: "center",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnHover: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 15000,
          pauseOnHover: true,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
       <div className="divmamann2" style={{ width: "100%" }}>
        {/* <button className="hackatonn" onClick={() => navigate("/Etudier_ESISA")}>
          En savoir plus
        </button>
        <h3 className="hackatonn1" onClick={() => navigate("/hackaton")}>
          Extension ESISA 2
        </h3> */}
        <img className="image" src={image2} alt="Extension ESISA 2" />
      </div>
      <div className="divmamann1" style={{ width: "100%" }}>
        <button className="hackatonn" onClick={() => navigate("/hackaton")}>
          En savoir plus
        </button>

        <h3 className="hackatonn1" onClick={() => navigate("/hackaton")}>
        Esisa Junior Developers Hackaton

        </h3>
        <img style={{ width: "100%" }} className="image" src={image3} alt="Hackathon" />
      </div>

   
    </Slider>
  );
};

export default PauseOnHover;
