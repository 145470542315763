import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function clermont() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/uca1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%', width:"80%" }} 
                />
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université Clermont Auvergne <Rentree />
            </a>
            <br/> 
            <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                FARHAN MOHAMMED - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Logiciel et Intégration d'Applications</span>
            </p>
           
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                OUKESSOU BAKR - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Génie Logiciel et Intégration d'Applications</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
            DOUKAR AYA -  <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>Systèmes d'Information et Aide à la décision</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
            EL KHADIR TAHA -  <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M1 Informatique ST Santé</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LEMSIAH EL YAZID - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M1 Informatique ST Santé</span>
            </p>
        </div>
    )
}
