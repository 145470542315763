import React from 'react';
import Rentree from './rentree.js';
import "./ecolePartenaire.css";

export default function Aix() {
  return (
    <div className="divecole3 fifty">
      <div className='imglogopartnaire'>
        <img 
          className='logoPartenaire2' 
          src="images/partenaire/amu.png" 
          style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' ,  width:"60%"}} 
        />
      </div>
      <br /> <br />
      <a style={{ fontSize: '18px', color: '#4e95ac' }}>
        Université Aix Marseille <Rentree />
      </a>
      <br /><br />
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        EL GHANDOURI CHAYMAE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 S3AD</span>
      </p>
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        EL KHEZZANE HAFSA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 S3AD</span>
      </p>
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        EL OUARDI NADA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 S3AD</span>
      </p>
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        LAZRAK AYA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 S3AD</span>
      </p>     
      
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        QASMI NABIL - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 S3AD</span>
      </p>
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        ZAHI FAHD - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 S3AD</span>
      </p>
    </div>
  );
}
