import React from 'react';
import './EsisaC19.css';
import c19 from "./cluster.png"
const EsisaC19 = () => {
  return (
    <div className="esisaC19-container">
      <div className="esisaC19-content">
        <h1 className="esisaC19-title">Partenariat ESISA CUBE & C-19</h1>
        <p className="esisaC19-description">
          L'ESISA CUBE a signé un partenariat avec <span className="esisaC19-highlight">C-19</span>, l'un des plus grands incubateurs de jeux vidéo en Europe. Ce partenariat permet aux étudiants de l'ESISA, de Polytechnique X et de CentraleSupélec d'accéder à un écosystème d'innovation de pointe.
        </p>
        
        <div className="esisaC19-image-placeholder">
          <img style={{height:"100%", borderRadius:"15px"}} src={c19} />
        </div>

        <p className="esisaC19-description">
          Cette collaboration ouvre de nouvelles opportunités aux futurs talents du gaming, en leur offrant un accompagnement d'excellence et un accès privilégié aux ressources et experts du secteur.
        </p>

        <p className="esisaC19-description">
          La gouvernance de <span className="esisaC19-highlight">C-19</span> inclut des institutions prestigieuses telles que l'<span className="esisaC19-highlight">ENSIIE</span>, l'<span className="esisaC19-highlight">IMT Télécom SudParis</span>, <span className="esisaC19-highlight">Polytechnique X</span> et bien d'autres.
        </p>

        <a href="https://c-19.fr/" target="_blank" rel="noopener noreferrer" className="esisaC19-button">
          Découvrir C-19
        </a>
      </div>
    </div>
  );
};

export default EsisaC19;
