import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function lyon() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/lyon1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%',  width:"80%" }} 
                />  
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université Lumière Lyon 2 <Rentree />
            </a>
            <br/> <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AATANY OUSSAMA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LAKHAL KHALID - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                MEZZOURH YASSINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LAZRAK MOHAMMED TAHA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 OPSIE</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                SLAOUI SALAH-EDDINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 OPSIE</span>
            </p>
        </div>
    )
}
