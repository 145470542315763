import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';
import "./ecolePartenaire.css"

export default function paris8() {
    return (
        <div className="divecole2 fifty">
            <div className='imglogopartnaire'>
                <img 
                    src="images/partenaire/paris81.png" 
                    className='logoPartenaire'                     style={{ paddingLeft: '0.2%', paddingBottom: '0.2%', width:"50%" }} 

                />
            </div>
            <br/> <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université de Paris 8 <Rentree />
            </a>
            <br /> <br />
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                DOUKKALI ZINEB - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}> M2 ACEHN</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LAMALLAM MALAK - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 ACEHN</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LOULIDI LAHKIM AKRAM - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 ACEHN</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                MAMOUNI-ALAOUI SALMA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 NET</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ERRIAHI EL IDRISSI BADR - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 NET</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                MEKKAOUI NADA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}> M2 NET</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BELLAJ ZAKARIAE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BOUSSAID AMINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BOUZIDI IDRISSI MOHAMMED - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                FAIZ AYA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 TH</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AISSAOUI IDRISSI ISSAM - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 TH</span>
            </p>
        </div>
    )
}
