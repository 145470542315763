import React from "react";
import Actualites from "../components/Actualites";
import Videos from "../components/Videos";

import Banner from "../components/Banner";
import Iframe from "../components/iframe";
import Chiffres from "../components/Chiffres";
export default function Accueil() {
  return (
    <div>
      <Chiffres />

      <Actualites />
      <Videos />

      <Banner />
      <Iframe />
    </div>
  );
}
