import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
export default function Navbar() {
  return (
    <div>
      <div class="navigation pull-left">
        <ul>
          <li class="active">
            <Link to="/">ACCUEIL</Link>
          </li>

          <li>
            <a>A PROPOS DE L'ESISA</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/Mot_president">
                  Mot du président
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Etudier_ESISA">
                  Etudier à l'ESISA
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/reconnuEsisa">
                  Reconnaisance de l'ecole
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Objectifs_ESISA">
                  Objectifs de l'ESISA
                </Link>
              </li>
              {/* <li>
                <Link class="navcolor" to="/Voix_ESISA">
                  La voix des anciens
                </Link>
              </li> */}
              {/* <li>
                <Link class="navcolor" to="/Conferences_ESISA">
                  Conférences à l'ESISA
                </Link>
              </li> */}
              <li>
                <Link class="navcolor" to="/Conferences_etranger">
                  Conférences à l'étranger
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Workshop">
                  Workshop
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Themes_recherche">
                  Thèmes de recherche
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>ENSEIGNEMENT</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/Systeme_enseignement">
                  Système d'enseignement
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Procedure_admission">
                  Procédure d'admission
                </Link>
              </li>

         



              <li>
                <a class="navcolor">Licence</a>
                <ul class="children" >
                
                  <li>
                    <Link class="navcolor" to="/Bac3">
                      <p>              Licence en Ingénierie Logicielle <strong>(IL)</strong> 
</p>
                      
                      
                    </Link>
                  </li>
              
                </ul>
              </li>



              <li>
                <a class="navcolor">Master</a>
                <ul class="children" >
                
                  <li>
                    <Link class="navcolor" to="/Bac5IS2IA">
                      <p>              Master Ingénierie des Systèmes d'Information appliquée à l'Intelligence Artificielle <strong>(IS2IA)</strong> 
</p>
                      
                      
                    </Link>
                  </li>
                  <li>
                    <Link class="navcolor" to="/Bac5ISITN">
                    <p className="psousmaster">              Master Ingénierie des Systèmes d'Information appliquée à la Transformation Numérique <strong>(ISITN)</strong>
</p>

                    </Link>
                  </li>
                </ul>
              </li>




              <li>
                <Link class="navcolor" to="/Ccna">
                  Formation CCNA(CISCO)
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>L'INTERNATIONAL</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/ESISA_International">
                  ESISA et L'international
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Admis_Etranger">
                  Admission partenaires universitaires
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>ENTREPRISES ET STAGES</a>
            <ul class="children">
              <li>
                <Link class="navcolor" to="/ESISAEntreprises">
                  ESISA-Entreprises
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Stages">
                  Stages
                </Link>
              </li>
              <li>
                <Link class="navcolor" to="/Emplois">
                  Emplois
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a>AUTRES</a>
            <ul class="children">
              <li>
                {/* <Link class="navcolor" to="/Presse">
                  Presse
                </Link> */}
              </li>

              <li>
                <Link class="navcolor" to="/esisa-analytica">
                  Laboratoire de recherche (LEA)
                </Link>
              </li>

              <li>
                <Link class="navcolor" to="/esisa-c19">
                  Incubateur Jeux Vidéos C-19
                </Link>
              </li>

              <li>
                <Link class="navcolor" to="/esisa-cube">
                  Incubateur d'entreprises (ESISA CUBE)
                </Link>
              </li>

              <li>
                <Link class="navcolor" to="/hackaton">
                Esisa Junior Developers Hackaton
                </Link>
              </li>

              <li>
                <Link class="navcolor" to="/reconnuEsisa">
                  Reconnaisance de l'ecole
                </Link>
              </li>

              <li>
                <Link class="navcolor" to="/brochureEsisa">
                  Brochure de l'établissement
                </Link>
              </li>



              <li>
                <Link class="navcolor" to="/Contactez-nous">
                  Contactez-nous
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="/PREINSCRIPTION">JE CANDIDATE</a>
          </li>

          <li>
            <a style={{ textTransform: "uppercase" }}>Espaces étudiant</a>{" "}
            <ul class="children">
              <li>
                <a class="navcolor" href="https://classroom.google.com/h">
                  Cours en Ligne
                </a>
              </li>
              <li>
                <a class="navcolor" href="https://esisa.org.ma/login/index.php">
                  Moodle{" "}
                </a>
              </li>
              <li>
                <a
                  class="navcolor"
                  href="https://esisa-portail-intranet.vercel.app/intranet"
                >
                  Intranet{" "}
                </a>
              </li>
            </ul>
          </li>

          <li class="active">
            <Link to="https://orbite.ma/esisa/">VISITE VIRTUELLE</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
