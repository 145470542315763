import React from "react";
import "./Preloader.css"; // Fichier CSS pour styliser l'animation

function Preloader() {
  return (
    <div className="preloader">
      <div className="preloader-logo">
        <img 
          src="images/logo/logoEsisa.png" 
          alt="ESISA Logo" 
        />
        <div className="loader-ring"></div>
      </div>
    </div>
  );
}

export default Preloader;
