import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EtudAdmis.css";
import Misc from "../Page/Admis/misc.js";
import Lille from "../Page/Admis/lille";
import Eil from "../Page/Admis/eil";
import Lyon from "../Page/Admis/lyon";
import Clermont from "../Page/Admis/clermont";
import Dijon from "../Page/Admis/dijon";
import Ensiie from "../Page/Admis/ensiie";
import Poly from "../Page/Admis/polytech";
import Paris8 from "../Page/Admis/paris8.js";
import Lorraine from "../Page/Admis/lorraine";
import Sorbonne from "../Page/Admis/sorbonne";
import CoteDazur from "./Admis/Cotedazur.js";
import Aix from "./Admis/Aix.js";
import Iscid from "./Admis/mba.js";
import NosPartenairesNew from "./International/NosPartenairesNew.js";

export default function EtudAdmis() {
  const [isMobile, setIsMobile] = useState(false);

  // Fonction pour vérifier la taille de l'écran
  const handleResize = () => {
    if (window.innerWidth <= 501) {
      setIsMobile(true); // Si la largeur de l'écran est <= 768px, on considère que c'est un écran mobile
    } else {
      setIsMobile(false); // Sinon, c'est un écran de taille normale
    }
  };

  useEffect(() => {
    handleResize(); // Vérifier la taille de l'écran au premier rendu
    window.addEventListener("resize", handleResize); // Ajouter un événement pour mettre à jour la taille lors du redimensionnement

    // Nettoyer l'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1, // Un seul slide à la fois
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1, // Toujours un seul slide
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Toujours un seul slide
        },
      },
    ],
  };

  // Les groupes de composants
  const slidesContent = [
    [<Poly />, <Ensiie />, <Eil />],
    [<CoteDazur />, <Lorraine />, <Aix />],
    [<Clermont />, <Lyon />, <Lille />],
    [<Paris8 />, <Misc />, <Dijon />],
  ];

  const slidesContentMobile = [
    [<Ensiie />],
    [<Poly />],
    [<Eil />],
    [<Aix />],
    [<Lille />],
    [<Lyon />],
    [<Paris8 />],
    [<Clermont />],
    [<Dijon />],
    [<CoteDazur />],
    [<Lorraine />],
    [<Misc />]
 
  ];

  // Utilisation de slidesContentMobile si c'est un écran mobile, sinon slidesContent
  const contentToDisplay = isMobile ? slidesContentMobile : slidesContent;

  return (
   <>




     <motion.div className="etud-admis-container" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.8 } }}>
      <motion.h2 className="etud-admis-title">Étudiants Admis en Master et Ecoles d'ingénieurs en France</motion.h2>
      <hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
      <motion.p className="etud-admis-description">
        Découvrez nos étudiants acceptés dans les meilleures écoles et universités d’ingénierie en France (<strong>95% de taux d'acceptation</strong>).
      </motion.p>
      <div className="etud-admis-slider">
        <Slider {...settings}>
          {contentToDisplay.map((group, index) => (
            <div key={index} className="etud-admis-slide">
              <div className="etud-admis-grid">
                {group.map((Component, idx) => (
                  <motion.div key={idx} className="etud-admis-card" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    {Component}
                  </motion.div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </motion.div>

    
    <NosPartenairesNew/>

   </>
  );
  
}
