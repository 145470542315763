import React from "react";
import { Link } from "react-router-dom";
import "./reconnuEsisa.css";

const ReconnuEsisa = () => {
  return (
    <section className="recognition-container">
      {/* Logo ESISA */}
      <div className="logo-container">
        <img
          src="images/logo/reconnu3.png"
          alt="ESISA Logo"
          className="esisa-logo"
        />
      </div>

      {/* Titre et contenu */}
      <h1 className="recognition-title">
        ESISA – École Supérieure d’Ingénierie en Sciences Appliquées
      </h1>
      <p className="recognition-subtitle">
        <strong className="strongestt">
          Établissement reconnu par l'État, reconnu pour l'excellence de sa
          formation
        </strong>
      </p>
      <p className="recognition-text">
        L’<strong className="strongest">ESISA</strong> est fière de faire partie
        des institutions reconnues par l’État, garantissant à ses étudiants une
        formation d’excellence et une équivalence automatique des diplômes avec
        ceux de l’État. Nos filières sont conçues pour répondre aux besoins des
        métiers d’avenir, alliant expertise technique et innovation.
        <br />
        <br />
        Une pédagogie innovante qui prépare à l’avenir, en intégrant les
        dernières avancées scientifiques et technologiques. Grâce à des
        partenariats avec des leaders internationaux, nous offrons à nos
        étudiants des opportunités uniques d’apprendre et de collaborer à
        l’échelle mondiale. Nos diplômés se distinguent dans des secteurs de
        pointe, contribuant activement au développement des technologies de
        demain.
        <br />
        <br />
        Rejoignez une formation qui ouvre les portes du succès, ici et ailleurs.
      </p>

      <p
        className="recognition-text"
        style={{
          fontWeight: "bold",
          textDecoration: "underline",
          textAlign: "center",
          padding: "1rem",
        }}
      >
        Découvrez nos trois programmes reconnus par l'état,
        <br /> adaptés aux aspirations des futurs ingénieurs et experts :
      </p>

      <ul className="recognition-list">
        <li>
          <strong className="strongest">
            Licence en Ingénierie Logicielle (IL)
          </strong>
          : <br />
          <br /> Une formation complète pour développer des logiciels complexes
          et maîtriser les outils de conception modernes, répondant aux
          exigences des entreprises nationales et internationales.
          <div className="button-container">
            <Link to="/Bac3" className="cta-button">
              Voir la Formation ( Licence IL )
            </Link>
          </div>
        </li>
        <li>
          <strong className="strongest">
            Master en Ingénierie des Systèmes d’Information Appliquée à
            l’Intelligence Artificielle (IS2IA)
          </strong>
          : <br />
          <br />
          Spécialisez-vous dans la création de solutions basées sur
          l’Intelligence Artificielle, le Machine Learning et le Deep Learning,
          et participez à la révolution technologique.
          <div className="button-container">
            <Link to="/Bac5IS2IA" className="cta-button">
              Voir la Formation ( Master IS2IA)
            </Link>
          </div>
        </li>
        <li>
          <strong className="strongest">
            Master en Ingénierie des Systèmes d’Information Appliquée à la
            Transformation Numérique (ISITN)
          </strong>
          : <br />
          <br />
          Préparez-vous à devenir un acteur clé de la transformation digitale,
          en maîtrisant les stratégies et technologies qui façonnent l’avenir
          des entreprises.
          <div className="button-container">
            <Link to="/Bac5ISITN" className="cta-button">
              Voir la Formation ( Master ISITN)
            </Link>
          </div>
        </li>
      </ul>
      <div className="button-container">
        <button
          className="cta-button"
          onClick={() => window.open("brochure.pdf", "_blank")}
        >
          Voir la Brochure générale
        </button>
      </div>
    </section>
  );
};

export default ReconnuEsisa;
