import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";  // Import du hook useNavigate
import "./Actualites.css";

export default function Actualites() {
  const navigate = useNavigate();  // Déclaration du hook navigate

  const actualites = [
    {
      imgSrc: "images/actu/cluster.png",
      altText: "Cluster C19",
      title: "Convention ESISA - C-19",
      description:
        "ESISA et Cluster C19 ont conclu une convention pour développer des jeux vidéo, marquant ainsi une collaboration innovante dans le domaine de l'éducation et du divertissement numérique.",
      route: "/esisa-c19",  // Route spécifique pour Cluster C19
    },
    {
      imgSrc: "images/actu/hackadton.png",
      altText: "Hackaton 2025",
      title: "Hackaton Junior Dev ESISA",
      description:
        "L'ESISA organise un Hackathon Junior Dev pour les lycéens, visant à stimuler la créativité et les compétences en développement de jeux vidéo. Cet événement marque une étape importante dans la promotion de l'éducation numérique et de l'innovation technologique auprès des jeunes talents.",
      route: "/hackaton",  // Route spécifique pour Hackaton
    },
    {
      imgSrc: "images/actu/esisa-cube.png",
      altText: "ESISA CUBE",
      title: "Lancement d'ESISA CUBE",
      description:
        "ESISA est fière de lancer **ESISA CUBE**, son incubateur d'entreprises dédié aux startups innovantes dans le domaine de l'intelligence artificielle. Ce centre d'innovation offre aux jeunes entreprises un environnement stimulant et des ressources de pointe pour transformer leurs idées novatrices en solutions concrètes. ",
      route: "/esisa-cube",  // Route spécifique pour ESISA CUBE
    },
    {
      imgSrc: "images/actu/master.jpg",
      altText: "Nouveaux Masters",
      title: "Nouveaux Masters",
      description:
        "Nous sommes ravis de vous présenter deux nouveaux masters : le Master IS2IA, qui forme des experts en systèmes d'information, intelligence artificielle et machine learning, et le Master ISITN, qui prépare des spécialistes de la transformation digitale avec une expertise en technologies émergentes et stratégies numériques.",
      route: "/Bac5IS2IA",  // Route pour les masters
    },
    {
      imgSrc: "images/actu/analytica.jpg",
      altText: "ESISA Analytica",
      title: "Lancement du Laboratoire ESISA ANALYTICA",
      description:
        "ESISA est fière d'annoncer le lancement de son laboratoire 'ESISA ANALYTICA'. Ce laboratoire est dédié à l'application de l'intelligence artificielle dans des domaines clés tels que la finance, la médecine, et l'écologie.",
      route: "/esisa-analytica",  // Route pour le laboratoire
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="center-slider-wrap">
      <div className="container" style={{ width: "auto", zoom: "100%" }}>
        <div className="iq_heading_1 text-center">
          <h4>
            <span>ACTUALITÉS</span>
          </h4>
        </div>

        <Slider {...settings}>
          {actualites.map((actualite, index) => (
            <div className="center-dec" key={index}>
              <figure>
                <img className="actu" src={actualite.imgSrc} alt={actualite.altText} />
              </figure>
              <div className="text" style={{ height: "37rem" }}>
                <h5>
                  <a href="#">{actualite.title}</a>
                </h5>
                <p>{actualite.description}</p>
                <button
                  className="btn-en-savoir-plus"  // Classe CSS pour le bouton
                  onClick={() => navigate(actualite.route)}  // Redirection dynamique
                >
                  En savoir plus
                </button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
