import React from 'react';
import './Emplois.css'; // Assurez-vous d'ajouter un fichier CSS correspondant pour les styles

const Emplois = () => {
  return (
    <>
      
      <div className="iq_heading_1 text-left" >

    <h4><span style={{fontSize:'25px'}}>Opportunités d'Emploi</span></h4>
</div>  
      <section className="emplois-content" style={{ padding: "15px 0 40px" }}>
        <div className="container">
          <p className="info-text">
            En tant qu'institution académique de prestige, nous connectons nos étudiants et anciens élèves avec des opportunités professionnelles de premier plan. Que vous soyez un jeune diplômé ou un docteur cherchant de nouveaux défis, nous vous invitons à soumettre vos offres d'emploi pour bénéficier de notre réseau étendu.
            <br />
            Pour soumettre vos offres, veuillez envoyer les détails à l'adresse suivante : 
            <a href="mailto:emplois@esisa.ac.ma" className="email-link">info@esisa.ac.ma</a>
          </p>
          <div className="button-container">
  <a href="https://www.linkedin.com/groups/10009730/" className="btn-join-alumni">
    Rejoindre ESISA alumni
  </a>
</div>

        </div>
      </section>
    </>
  );
};

export default Emplois;
