import React from 'react';
import NosPartenaires from '../../components/NosPartenaires';
import './Themes_recherche.css'; 

export default function ThemesRecherche() {
  return (
    <>
      <div className="iq-heading">
        <h1 
  className='afterrr' 
  style={{ 
    fontSize: '2.5rem', 
    fontWeight: '700', 
    color: '#4cb6ea' 
  }}
>
 THÈMES DE RECHERCHE
</h1>
<hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
      </div>

      <div className="themes-recherche-container">
        <div className="content-wrapper">
          <p className="introduction">
            La recherche a été initiée à l'ESISA par son Président et Directeur pédagogique M. MEKOUAR Khalid après son palmarès glorieux en France (Nice Sophia Antipolis) Prix Sadi Carnot 1991.
          </p>

          <div className="themes-grid">
            {[
              "Bases de Données évoluées",
              "Bases de Données MultiMedia",
              "Intelligence Artificielle",
              "Big Data",
              "Data sciences",
              "Internet des Objets (IOT)",
              "Structures de Données évoluées",
              "Systèmes distribués",
              "Systèmes Experts",
              "Traitement d'Images",
              "Télé Médecine",
              "Télé Enseignement",
              "EIAO: Enseignement Intelligent assisté par ordinateur",
              "Cybersécurité","IA appliquée à la finance","IA appliquée à la medecine","IA appliquée à l'écologie",
              "Blockchain"
            ].map((theme, index) => (
              <div className="theme-card" key={index}>
                <a href="#">{theme}</a>
              </div>
            ))}
          </div>

          <p className="conclusion">
            L'ESISA est considérée comme un cycle préparatoire à la recherche. Elle donne le goût et les moyens nécessaires de la recherche à ses jeunes étudiants en leur apprenant les bonnes démarches et méthodes pour y aboutir et en les initiant aux nouvelles technologies.
          </p>
        </div>

        <div className="nos-partenaires">
          <NosPartenaires />
        </div>
      </div>
    </>
  );
}
