import React from 'react';
import './PolitiqueConfidentialité.css';

const PolitiqueConfidentialite = () => {
  return (

    <div style={{display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center"}}>
        <div  className="privacy-container">
      <h1 className="privacy-title">Politique de Confidentialité</h1>
      <hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
      
      <section className="privacy-section">
        <h2 style={{fontWeight:"bold"}}>1. Introduction</h2>
        <p>
          Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos données personnelles conformément aux réglementations de la CNDP au Maroc.
        </p>
      </section>

      <section className="privacy-section">
        <h2 style={{fontWeight:"bold"}}>2. Données collectées</h2>
        <p>
          Nous pouvons collecter les types de données suivants :  
          - Informations d'identification (nom, prénom, email, téléphone).  
          - Données de navigation et cookies.  
          - Toute information fournie via nos formulaires.
        </p>
      </section>

      <section className="privacy-section">
        <h2 style={{fontWeight:"bold"}}>3. Finalité de la collecte</h2>
        <p>
          Vos données sont collectées dans le but de :  
          - Fournir nos services et gérer votre inscription.  
          - Améliorer l'expérience utilisateur sur notre site.  
          - Assurer le respect des obligations légales et réglementaires.
        </p>
      </section>

      <section className="privacy-section">
        <h2 style={{fontWeight:"bold"}}>4. Droits des utilisateurs</h2>
        <p>
          Conformément à la loi marocaine N° 09-08, vous avez le droit d’accéder, de rectifier et de supprimer vos données personnelles.  
          Vous pouvez également vous opposer à leur traitement en nous contactant à l’adresse suivante : <strong>info@esisa.ac.ma</strong>.
        </p>
      </section>

      <section className="privacy-section">
        <h2 style={{fontWeight:"bold"}}>5. Sécurité des données</h2>
        <p>
          Nous mettons en place des mesures techniques et organisationnelles strictes pour protéger vos données contre tout accès non autorisé, perte ou destruction.
        </p>
      </section>

      <section className="privacy-section">
  <h2 style={{fontWeight:"bold"}}>6. Contact</h2>
  <p>
    Pour toute question relative à cette politique, veuillez nous contacter à :  
    <strong>
      <a style={{color:"#4cb6ea", fontWeight:"bold"}} href="mailto:info@esisa.ac.ma" className="privacy-email">info@esisa.ac.ma</a>
    </strong>.
  </p>
</section>

    </div>
    </div>
  
  );
}

export default PolitiqueConfidentialite;
