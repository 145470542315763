import React from 'react';
import Rentree from './rentree.js';
import "./ecolePartenaire.css";

export default function CoteDazur() {
  return (
    <div className="divecole3 fifty">
      <div className='imglogopartnaire'>
        <img 
          className='logoPartenaire2' 
          src="images/partenaire/cotedazur.png" 
          style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' , width:"40%"}} 
        />
      </div>
      <br /> <br />
      <a style={{ fontSize: '18px', color: '#4e95ac' }}>
        Université Côte d'Azur <Rentree />
      </a>
      <br /><br />
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        EL MOUDDEN RIAD - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIAGE - NUMRES</span>
      </p>
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        TRIBAK ANAS - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIAGE - NUMRES</span>
      </p>
      <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
        FARIKAL AMINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M1 MIAGE</span>
      </p>
      <p> </p> {/* Placeholder for spacing */}
      <p></p> {/* Placeholder for spacing */}
    </div>
  );
}
