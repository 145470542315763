import React, { Component } from "react";
import Slider from "react-slick";
import YouTube from "react-youtube";
import "./Videos.css";

export default class PauseOnHover extends Component {
  slider = React.createRef();

  state = {
    isSwiping: false
  };

  handleSwipeStart = (e) => {
    this.swipeStartX = e.touches[0].clientX;
    this.swipeStartY = e.touches[0].clientY;
    this.setState({ isSwiping: false });
  };

  handleSwipeMove = (e) => {
    const deltaX = e.touches[0].clientX - this.swipeStartX;
    const deltaY = e.touches[0].clientY - this.swipeStartY;

    if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
      this.setState({ isSwiping: true });
    }
  };

  handleSwipeEnd = (e) => {
    const deltaX = e.changedTouches[0].clientX - this.swipeStartX;

    if (Math.abs(deltaX) > 50) {
      if (deltaX < 0) {
        this.slider.current.slickNext();
      } else {
        this.slider.current.slickPrev();
      }
    }
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 7777,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
          }
        }
      ]
    };

    const videoOptions = {
      playerVars: {
        autoplay: 0,
        mute: 0,
        loop: 1,
        controls: 0,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
      },
    };

    const videoIds = ["9r6ZDfzEr0Y", "1y1d6cxdqlA"];

    return (
      <section style={{ backgroundColor: '#f8f8f8' }}>
      <div className="partners-heading" style={{ textAlign: "center" }}>
      <h1 
  className='afterrr' 
  style={{ 
    fontSize: '2.5rem', 
    fontWeight: '700', 
    marginBottom: '1rem', // Réduit pour rapprocher le <hr>
    color: '#4cb6ea' 
  }}
>
  Vidéos
</h1>
<hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
      </div>
        <div className="container" style={{ width: 'auto', zoom: '100%' }}>
          <Slider ref={this.slider} {...settings}>
            {videoIds.map((id) => (
              <div key={id} className="video-container">
                <YouTube videoId={id} opts={videoOptions} className="youtube-video" />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}
