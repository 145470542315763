import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import NosPartenaires from '../../components/NosPartenaires';

export default function ESISAEntreprises() {
  return (
    <Container>
      <Content>
        <h1 
          className='afterrr' 
          style={{ 
            fontSize: '2.5rem', 
            fontWeight: '800', 
            marginBottom: '1rem', 
            color: '#4cb6ea' ,
            textAlign:"center"

          }}
        >
          INTRODUCTION
        </h1>
        <hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
        <TextContainer
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <p>
            Notre ambition est que les étudiants que nous formons aujourd'hui deviennent, demain, des
            collaborateurs efficaces, capables de prendre des responsabilités dans un environnement
            professionnel en constante évolution. Pour y parvenir, une collaboration étroite avec
            vous, entreprises partenaires, est essentielle.
          </p>
          <p>
            Cette section vise à renforcer les échanges entre les entreprises et l'ESISA en proposant
            divers types de partenariats. Nous sommes à votre écoute et prêts à explorer toute
            opportunité de collaboration.
          </p>
          <p>
            📩 Contactez-nous à :{' '}
            <a href="mailto:info@esisa.ac.ma">info@esisa.ac.ma</a>
          </p>
        </TextContainer>

        <h1 
          className='afterrr' 
          style={{ 
            fontSize: '2.5rem', 
            fontWeight: '800', 
            marginBottom: '1rem', 
            color: '#4cb6ea', 
            textAlign:"center"
          }}
        >
          Relations Entreprises
        </h1>
        <hr 
  style={{ 
    border: 'none', 
    borderTop: '1px solid #ccc', // Ligne fine et gris clair
    marginBottom: '2rem', 
    width: '25%' // Optionnel, pour centrer et limiter la largeur
  }} 
/>
        <TextContainer>
        <p>
  Fondée en 1999, l'ESISA est pionnière dans le domaine de la formation en Ingénierie Informatique au Maroc. Aujourd'hui, elle compte parmi ses partenaires plus de 100 entreprises, tant publiques que privées, illustrant ainsi la reconnaissance et la confiance dont elle bénéficie dans le milieu professionnel.
</p>
<p>
  « Choisir l'ESISA, c'est opter pour l'excellence ! » affirment les responsables des Ressources Humaines. Nos étudiants se distinguent par leur capacité d'autonomie, leur rigueur intellectuelle, ainsi que leurs compétences pratiques, acquises au travers d'un programme innovant et de stages professionnels exigeants. Leur formation les prépare à relever les défis du monde professionnel avec aisance et expertise.
</p>

        </TextContainer>
      </Content>
    </Container>
  );
}

// 🌟 STYLES MODERNES

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 40px 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
  width: 100%;
  text-align: left;
`;



const TextContainer = styled.div`
  font-size: 16px;
  color: #333;
  line-height: 1.7;
  text-align: justify;
  margin-bottom: 20px;

  a {
    color: #1962e5;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;
