import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';
import "./ecolePartenaire.css"

export default function Lorraine() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/lor1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%', width:"80%" }} 
                />
            </div>
            <br/>
            <a style={{ color: '#4e95ac', fontSize: '18px' }}>
                Lorraine <Rentree />
            </a>
            <br/> <br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                EL IDRISSI YASSINE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 Systèmes d'Information Décisionnels</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BARGHOUTI MOHAMMED TAHA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 Systèmes d'Information Décisionnels</span>
            </p>
           
        </div>
    )
}
