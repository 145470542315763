import React, { useState, useEffect } from "react";

// Utilisation de l'API des cookies pour gérer les préférences
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
};

const CookiePreferences = () => {
  const [preferences, setPreferences] = useState({
    essential: true,
    analytics: false,
  });

  // Charger les préférences sauvegardées depuis les cookies
  useEffect(() => {
    const savedPreferences = getCookie("cookiePreferences");
    if (savedPreferences) {
      setPreferences(JSON.parse(savedPreferences));
    }
  }, []);

  // Mettre à jour les préférences dans le state
  const handleToggle = (category) => {
    setPreferences((prev) => {
      const newPreferences = { ...prev, [category]: !prev[category] };
      setCookie("cookiePreferences", JSON.stringify(newPreferences), 365); // Sauvegarder dans un cookie
      return newPreferences;
    });
  };

  // Sauvegarder les préférences dans un cookie
  const handleSave = () => {
    console.log("Préférences sauvegardées:", preferences);
    setCookie("cookiePreferences", JSON.stringify(preferences), 365); // Sauvegarder dans un cookie
    alert("Vos préférences de cookies ont été sauvegardées.");
  };

  return (
    <div className="container" style={styles.container}>
      <h1 style={styles.title}>Gérer vos préférences de cookies</h1>
      <p style={styles.paragraph}>
        Vous pouvez personnaliser vos préférences en matière de cookies ci-dessous. Les cookies essentiels sont toujours
        activés car ils sont nécessaires au bon fonctionnement du site.
      </p>

      <div style={styles.cookieCategories}>
        <div style={styles.cookieOption}>
          <label style={styles.label}>
            <input
              type="checkbox"
              checked={preferences.essential}
              disabled
              style={styles.checkbox}
            />
            <span>Cookies essentiels (toujours activés)</span>
          </label>
          <p style={styles.description}>
            Ces cookies sont nécessaires au bon fonctionnement du site web et ne peuvent pas être désactivés.
          </p>
        </div>

        <div style={styles.cookieOption}>
          <label style={styles.label}>
            <input
              type="checkbox"
              checked={preferences.analytics}
              onChange={() => handleToggle("analytics")}
              style={styles.checkbox}
            />
            <span>Cookies analytiques</span>
          </label>
          <p style={styles.description}>
            Ces cookies nous aident à comprendre comment les visiteurs interagissent avec le site en collectant des
            informations de manière anonyme.
          </p>
        </div>
      </div>

      <button onClick={handleSave} style={styles.button}>
        Sauvegarder mes préférences
      </button>
    </div>
  );
};

// Styles modernes et épurés
const styles = {
  container: {
    padding: "2rem",
    color: "#051951",
    fontFamily: "'Montserrat', sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#fff",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#4cb6ea",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  paragraph: {
    fontSize: "1rem",
    lineHeight: "1.6",
    color: "#333",
    textAlign: "center",
    marginBottom: "2rem",
  },
  cookieCategories: {
    marginBottom: "2rem",
  },
  cookieOption: {
    marginBottom: "1.5rem",
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  checkbox: {
    marginRight: "0.8rem",
    width: "20px",
    height: "20px",
  },
  description: {
    marginLeft: "2.5rem",
    fontSize: "0.9rem",
    color: "#666",
  },
  button: {
    backgroundColor: "#4cb6ea",
    color: "white",
    border: "none",
    padding: "0.8rem 1.5rem",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1.1rem",
    display: "block",
    width: "100%",
    textAlign: "center",
    marginTop: "1rem",
    transition: "background-color 0.3s ease",
  },
};

export default CookiePreferences;
