import React from "react";
import "./Brochure.css";

const Brochure = () => {
  return (
    <div className="brochure-container">
      <h1 style={{ color: "#4cb6ea", fontWeight: "bold", fontSize: "2rem", marginBottom: "2rem" }} className="brochure-title">Brochure 2024-2025</h1>
      <p className="brochure-description">
      Découvrez la brochure officielle de l'ESISA et explorez notre vision d'excellence académique et d'innovation technologique avec l’inauguration du laboratoire de recherche LEA et de notre incubateur d’entreprises ESISA CUBE.

Nos nouveaux Masters de pointe, ISITN (Ingénierie des Systèmes d'Information appliquée à la Transformation Numérique) et IS2IA (Ingénierie des Systèmes d'Information appliquée à l'Intelligence Artificielle), offrent une formation d’élite en IA, transformation digitale et technologies émergentes.      </p>
      <a href="/brochure.pdf" download="Brochure_ESISA.pdf" className="brochure-button">
        Télécharger la Brochure 📄
      </a>
    </div>
  );
};

export default Brochure;
