import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // Import de framer-motion
import "./Chiffres.css"

const Chiffres = () => {
  const [inView, setInView] = useState(false);
  const [values, setValues] = useState({
    tauxInsertion: 94,
    partenairesAcad: 15,
    acceptesFrance: 95,
    reconnusParEtat: 0,
  });

  // Fonction pour détecter quand la section entre en vue
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('chiffres-section');
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          setInView(true); // Lance l'animation quand l'élément est visible
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Fonction d'animation des chiffres
  useEffect(() => {
    if (inView) {
      // Mise à jour progressive des valeurs
      setValues({
        tauxInsertion: 94,
        partenairesAcad: 15,
        acceptesFrance: 95,
        reconnusParEtat: 1,
        laureats: 2000,
        ansExperience: 26,
      });
    }
  }, [inView]);

  return (
    <div
      id="chiffres-section"
      style={{
        padding: '4rem 2rem',
        backgroundColor: '#F7F7F7',
        textAlign: 'center',
        color: '#051951',
        fontFamily: "'Roboto', sans-serif",
        overflow: 'hidden'      }}
    >
      <h1
        className='afterrr'
        style={{
          fontSize: '2.5rem',
          fontWeight: '700',
          marginBottom: '1rem',
          color: '#4cb6ea'
        }}
      >
        Les Chiffres Clés de l'ESISA
      </h1>
      <hr
        style={{
          border: 'none',
          borderTop: '1px solid #ccc',
          marginBottom: '2rem',
          width: '25%'
        }}
      />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
          gap: '3rem',
          marginTop: '3rem',
        }}
      >
        {/* Taux d'insertion */}
        <motion.div
          className='divchiffre'
          style={{
            backgroundColor: '#ffffff',
            padding: '3rem',
            borderRadius: '12px',
            boxShadow: '0 12px 30px rgba(0, 0, 0, 0.1)',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 1 }}
        >
          <h2
            style={{
              fontSize: '1.8rem',
              fontWeight: '600',
              color: '#4cb6ea',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              marginBottom: '1.5rem',
            }}
          >
            Taux d'insertion professionnel
          </h2>
          <motion.p
            style={{
              fontSize: '1.3rem',
              fontWeight: '300',
              color: 'rgb(76, 182, 234)',
              letterSpacing: '1px',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
            key="tauxInsertion" // Ajout d'un key unique pour l'animation
          >
            <motion.span
              initial={{ width: 0 }}
              animate={{ width: `${values.tauxInsertion}%` }}
              transition={{
                duration: 2, 
                ease: "easeOut", 
              }}
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
              }}
            >
              {`100% des diplômés`}
            </motion.span>
          </motion.p>
        </motion.div>

        {/* Partenaires académiques */}
        <motion.div
          className='divchiffre'
          style={{
            backgroundColor: '#ffffff',
            padding: '3rem',
            borderRadius: '12px',
            boxShadow: '0 12px 30px rgba(0, 0, 0, 0.1)',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <h2
            style={{
              fontSize: '1.8rem',
              fontWeight: '600',
              color: 'rgb(76, 182, 234)',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              marginBottom: '1.5rem',
            }}
          >
            Partenaires académiques internationaux
          </h2>
          <motion.p
            style={{
              fontSize: '1.3rem',
              fontWeight: '300',
              color: 'rgb(76, 182, 234)',
              letterSpacing: '1px',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 1 }}
          >
            <motion.span
              initial={{ width: 0 }}
              animate={{ width: `${values.partenairesAcad}%` }}
              transition={{
                duration: 2, 
                ease: "easeOut",
              }}
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
              }}
            >
              {`${values.partenairesAcad} Universités et écoles d'ingénieurs`}
            </motion.span>
          </motion.p>
        </motion.div>

        {/* Acceptés en France */}
        <motion.div
          className='divchiffre'
          style={{
            backgroundColor: '#ffffff',
            padding: '3rem',
            borderRadius: '12px',
            boxShadow: '0 12px 30px rgba(0, 0, 0, 0.1)',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 1 }}
        >
          <h2
            style={{
              fontSize: '1.8rem',
              fontWeight: '600',
              color: '#4cb6ea',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              marginBottom: '1.5rem',
            }}
          >
            Acceptés en Master 2 / 4ème Année d'écoles d'Ingénieurs
          </h2>
          <motion.p
            style={{
              fontSize: '1.3rem',
              fontWeight: '300',
              color: 'rgb(76, 182, 234)',
              letterSpacing: '1px',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 1 }}
          >
            <motion.span
              initial={{ width: 0 }}
              animate={{ width: `${values.acceptesFrance}%` }}
              transition={{
                duration: 2, 
                ease: "easeOut",
              }}
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
              }}
            >
              {`${values.acceptesFrance}% des étudiants de Master 1 IS2IA/ISITN`}
            </motion.span>
          </motion.p>
        </motion.div>

        {/* Reconnue par l'État */}
        <motion.div
          className='divchiffre'
          style={{
            backgroundColor: '#ffffff',
            padding: '3rem',
            borderRadius: '12px',
            boxShadow: '0 12px 30px rgba(0, 0, 0, 0.1)',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 1 }}
        >
          <h2
            style={{
              fontSize: '1.8rem',
              fontWeight: '600',
              color: '#4cb6ea',
              textTransform: 'uppercase',
              letterSpacing: '1px',
              marginBottom: '1.5rem',
            }}
          >
            Reconnaissance de l'établissement
          </h2>
          <motion.p
            style={{
              fontSize: '1.3rem',
              fontWeight: '300',
              color: 'rgb(76, 182, 234)',
              letterSpacing: '1px',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <motion.span
              initial={{ width: 0 }}
              animate={{ width: '100%' }}
              transition={{
                duration: 2, 
                ease: "easeOut",
              }}
              style={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontWeight: 'bold',
              }}
            >
              Etablissement reconnu par l'état
            </motion.span>
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default Chiffres;
