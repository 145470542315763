import React from "react";
import "./ESISA_International.css";
import NosPartenairesNew from "../International/NosPartenairesNew.js";

export default function ESISA_International() {
  return (
    <div className="esisa-international-container">
      {/* Header */}
      <header className="esisa-international-header">
        <h1 className="title-glow" style={{marginBottom:"1rem"}}>🌍 L'ESISA et l'International</h1>
        <p style={{color:"white"}}>
          Accédez à l'excellence mondiale grâce à nos collaborations de prestige 
          et aux opportunités académiques et professionnelles internationales.
        </p>
      </header>

      {/* Section Contenu */}
      <section  className="esisa-international-content" style={{padding:"0"}}>
        <p>
          🚀 <strong>Une formation tournée vers l’international :</strong>
          L'ESISA ouvre à ses étudiants les portes d’une carrière mondiale grâce 
          à ses partenariats avec des institutions de renom en France, aux États-Unis 
          et au-delà. Nos programmes sont conçus pour offrir une expérience académique 
          et professionnelle enrichissante.
        </p>

        <div className="highlight-card">
          <h3>📊 Stages à l'étranger</h3>
          <p style={{color:"white"}}>
            - <strong style={{color:"white"}}>3ème année :</strong> Stage de 2 à 3 mois dans des entreprises partenaires.  
            <br />- <strong style={{color:"white"}}>5ème année :</strong> Stage de 4 à 6 mois dans des secteurs innovants.
          </p>
        </div>

        <div className="highlight-card">
          <h3>📚 Compétences linguistiques</h3>
          <p style={{color:"white"}}>
            - Préparation avancée au <strong style={{color:"white"}}>TOEIC</strong> pour une maîtrise de l’anglais professionnel.  
            <br />- Certification B2/C1 en français grâce à notre partenariat avec l'<strong style={{color:"white"}}>Institut Français</strong>.
          </p>
        </div>

        <div className="highlight-card">
          <h3>🌐 Carrières globales</h3>
          <p style={{color:"white"}}>
            Chaque année, nos étudiants poursuivent leurs études dans des 
            établissements de prestige ou débutent leur carrière dans des environnements internationaux.
          </p>
          <p style={{color:"whitesmoke"}}>Pour l'année universitaire 2025/2026,<strong style={{color:"white"}}>95%</strong>  de nos étudiants de master 1 IS2IA ont intégrés des masters dans universités presitigieuses et dans des écoles d'ingénieurs d'état en France.</p>
        </div>
      </section>

      {/* Section Partenaires */}
      <section style={{padding:"0"}} className="esisa-international-partners">
        <NosPartenairesNew />
      </section>
    </div>
  );
}
