import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaPaperPlane, FaDownload, FaGraduationCap, FaLaptopCode, FaBrain, FaCode } from 'react-icons/fa';
import { motion, useScroll, useTransform } from 'framer-motion';
import reconnu from './reconnu3.png';
import './Bac5ISITN.css';

export default function Bac5ISITN() {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.2], [1, 0.8]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCandidaterClick = () => {
    navigate('/PREINSCRIPTION');
  };

  const handleDownloadClick = () => {
    window.open('images/final/ISITN.pdf', '_blank');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  return (
    <motion.div 
      className="isitn-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <img src={reconnu} alt="Formation Reconnue" className="recognition-badge" />
      <motion.div 
        className="isitn-header" 
        variants={itemVariants}
        style={{ opacity, scale }}
      >
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <FaGraduationCap size={60} color="#4cb6ea" />
        </motion.div>
        <h1 className="isitn-title">
          INGÉNIERIE DES SYSTÈMES D'INFORMATION APPLIQUÉE À LA TRANSFORMATION NUMÉRIQUE (ISITN)
        </h1>
        <h2 className="isitn-subtitle">Master en Ingénierie des Systèmes d'Information & Transformation Numérique</h2>
      </motion.div>

      <motion.div className="isitn-content" variants={itemVariants}>
        <motion.p className="program-description" variants={itemVariants}>
          Formation d'excellence reconnue par l'État, notre Master en Ingénierie des Systèmes d'Information et Transformation Numérique (ISITN) est un diplôme prestigieux qui prépare les futurs leaders de la transformation numérique. Cette formation innovante de deux ans combine expertise technique et vision stratégique, permettant aux étudiants de maîtriser les technologies émergentes et les méthodologies de transformation digitale. Le programme est disponible en formation initiale ou en alternance, offrant une expérience professionnelle enrichissante avec 2 jours en entreprise et 3 jours à l'école.
        </motion.p>

        <motion.div className="program-features" variants={itemVariants}>
          <div className="feature-grid">
            <div className="feature-item">
              <FaGraduationCap size={30} color="#4cb6ea" />
              <h4>Formation en Alternance</h4>
              <p>2 jours en entreprise (Lundi-Mardi) et 3 jours à l'école (Mercredi-Vendredi) pour une expérience professionnelle enrichissante</p>
            </div>
            <div className="feature-item">
              <FaCode size={30} color="#4cb6ea" />
              <h4>Expertise Digitale</h4>
              <p>Maîtrise des technologies de transformation numérique et des méthodologies agiles</p>
            </div>
            <div className="feature-item">
              <FaBrain size={30} color="#4cb6ea" />
              <h4>Vision Stratégique</h4>
              <p>Développement de compétences en leadership et gestion de projets digitaux</p>
            </div>
          </div>
        </motion.div>

        <motion.div className="program-opportunities" variants={itemVariants}>
          <h3 className="opportunities-title">Débouchés d'Excellence</h3>
          <div className="opportunities-grid">
            <div className="opportunity-block">
              <h4>Carrières Prometteuses</h4>
              <ul>
                <li>Directeur de la Transformation Numérique</li>
                <li>Architecte Solutions Digitales</li>
                <li>Chef de Projet Innovation Digitale</li>
                <li>Consultant en Transformation Digitale</li>
                <li>Responsable des Systèmes d'Information</li>
              </ul>
            </div>
            <div className="opportunity-block">
              <h4>Expertise Acquise</h4>
              <ul>
                <li>Transformation Digitale des Entreprises</li>
                <li>Gouvernance des Systèmes d'Information</li>
                <li>Innovation et Technologies Émergentes</li>
                <li>Gestion de Projets Agiles</li>
                <li>Cybersécurité et Protection des Données</li>
              </ul>
            </div>
          </div>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <FaCode size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">1er Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.1 - Génie Logiciel & Ingénierie du Web</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.2 - Marketing Digital</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.3 - IA et IoT pour le Web</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.4 - Bases de données Avancées</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.5 - Design Thinking & Business Plan</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.6 - Culture et Communication</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S1.7 - Power Skills (Développement personnel)</motion.li>
          </ul>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <FaBrain size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">2ème Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISITN S2.1 - Cadrage d'un Projet SI</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S2.2 - Gestion et Planification de projet agile</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S2.3 - Composants Logiciels pour l'Entreprise</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S2.4 - Management de Projet Informatique</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S2.5 - Big Data et Data Mining</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S2.6 - Culture et Communication 2</motion.li>
          </ul>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <FaLaptopCode size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">3ème Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISITN S3.1 - Transformation Digitale & Innovation</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S3.2 - Blockchain & Smart Contracts</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S3.3 - Cloud Computing & DevOps</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S3.4 - Cybersécurité & RGPD</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S3.5 - Projet de Synthèse</motion.li>
            <motion.li className="course-item" variants={itemVariants}>ISITN S3.6 - Culture et Communication 3</motion.li>
          </ul>
        </motion.div>

        <motion.div className="semester-section" variants={cardVariants}>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <FaGraduationCap size={40} color="#4cb6ea" style={{ marginBottom: '1rem' }} />
          </motion.div>
          <h3 className="semester-title">4ème Semestre</h3>
          <ul className="course-list">
            <motion.li className="course-item" variants={itemVariants}>ISITN S4 - Stage de Fin d'Études</motion.li>
          </ul>
        </motion.div>

        <motion.div 
          className="action-buttons" 
          variants={itemVariants}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.7 }}
        >
          <Button
            variant="primary"
            className="btn btn-primary"
            onClick={handleCandidaterClick}
          >
            <FaPaperPlane />
            Candidater
          </Button>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleDownloadClick}
          >
            <FaDownload />
            Télécharger la brochure
          </Button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
