import React from 'react'
import Rentree from '../../Page/Admis/rentree.js';

export default function lille() {
    return (
        <div className="divecole fifty">
            <div className='imglogopartnaire'>
                <img 
                    className='logoPartenaire' 
                    src="images/partenaire/lille1.png" 
                    style={{ paddingLeft: '0.2%', paddingBottom: '0.2%' ,  width:"80%"}} 
                />
            </div>
            <br/>
            <a style={{ fontSize: '18px', color: '#4e95ac' }}>
                l'Université de Lille <Rentree />
            </a>
            <br /><br/>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                AMJAHID REWAE - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIASHS spécialité Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                ABDELLAH QATRE EN NADA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIASHS spécialité Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BOUANANI MARYEM - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIASHS spécialité Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LAKTATI MEHDI - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIASHS spécialité Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                BOUKIR REDA - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIASHS spécialité Web Analyste</span>
            </p>
            <p style={{ fontWeight: "800", fontSize: "1.5rem" }}>
                LALMICHE OMAR - <span style={{ color: "#4cb6ea", fontSize: "1.5rem" }}>M2 MIASHS spécialité Web Analyste</span>
            </p>
           
        </div>
    )
}
