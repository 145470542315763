import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Preinscription.css";
import NosPartenaires from "../../components/NosPartenaires";

export default function Preinscription() {
  const [autreVisible, setAutreVisible] = useState(false);
  const [autreValue, setAutreValue] = useState("");
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [baccalaureat, setBaccalaureat] = useState("Sciences Mathématiques");
  const [contactMethod, setContactMethod] = useState("");
  const [niveau, setNiveau] = useState("");
  const [consent, setConsent] = useState(false);

  const navigate = useNavigate();

  const handleBaccalaureatChange = (event) => {
    const { value } = event.target;
    setBaccalaureat(value);
    setAutreVisible(value === "Autre");
    if (value !== "Autre") setAutreValue("");
  };

  const handleConsentChange = (event) => setConsent(event.target.checked);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!consent) {
      setNotification("⚠️ Vous devez accepter le traitement des données.");
      return;
    }

    const formData = new FormData(event.target);
    if (autreVisible && autreValue) {
      formData.set("baccalaureat", autreValue);
    }

    setIsLoading(true);
    setLoadingPercentage(0);

    try {
      const response = await axios.post("https://esisabackend.vercel.app/api/info", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            setLoadingPercentage(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }
        },
      });

      if (response.status === 200) {
        setNotification("✅ Formulaire envoyé avec succès !");
        setShowModal(true);
        event.target.reset();
        setAutreVisible(false);
      } else {
        throw new Error("Erreur lors de l'envoi du formulaire.");
      }
    } catch (error) {
      setNotification("❌ Une erreur est survenue. Contactez-nous à info@esisa.ac.ma ou au +212 (0)6 61 45 35 09.");
      console.error("Erreur lors de l'envoi :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };
  
  return (
    <div className="formcandidate">
      <NosPartenaires />

      <div
        className="formtodo"
        style={{
          padding: "20px",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div className="iq_heading_1 text-left">
          <h4>
            <span style={{ fontSize: "2.5rem", textAlign: "center" }}>
              {" "}
              DEMANDE DE CANDIDATURE
            </span>
          </h4>
        </div>

        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <div>
            <input type="radio" name="genre" value="Homme" defaultChecked />{" "}
            Homme &nbsp;&nbsp;
            <input type="radio" name="genre" value="Femme" /> Femme
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ flex: 1 }}>
              <label>
                Nom <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="nom"
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>
                Prénom <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="prenom"
                required
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
          </div>

          <div className="numberandmail" style={{ display: "flex", gap: "10px" }}>

          <div style={{width:"50%"}}>
            <label>
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="email"
              name="email"
              required
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                textTransform: "none",
              }}
            />
          </div>

          <div style={{width:"50%"}}>
            <label>
              Téléphone <span style={{ color: "red" }}>*</span>
            </label>
            <PhoneInput
  className="phonetel"
  country={"ma"}
  value={phone}
  onChange={(value) => setPhone(value)}
  inputProps={{ name: "tel", required: true }}
  containerStyle={{ width: "100%" }}
  inputStyle={{ width: "100%" }}
/>

          </div>
</div>
          <div>
            <label>
              Type de Baccalauréat <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            {[
              "Sciences Mathématiques",
              "Sciences Physiques",
              "Sciences de la Vie et de la Terre",
              "Sciences Economiques",
              "Technique",
            ].map((type) => (
              <div key={type}>
                <input
                  type="radio"
                  name="baccalaureat"
                  value={type}
                  onChange={handleBaccalaureatChange}
                  checked={baccalaureat === type}
                />{" "}
                {type}
              </div>
            ))}
            <div>
              <input
              
                type="radio"
                name="baccalaureat"
                value="Autre"
                onChange={handleBaccalaureatChange}
                checked={baccalaureat === "Autre"}
              />{" "}
              Autre
            </div>

            {autreVisible && (
             <div>
             <input
             className="inputbacautre"
                type="text"
                placeholder="Précisez votre baccalauréat"
                value={autreValue}
                onChange={(e) => setAutreValue(e.target.value)}
              />
             </div>
            )}
          </div>
          <div>
            <label>
              Choisissez une filière <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="filiere"
              required
              defaultValue="Bac +3 Ingénierie Logicielle"
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              <option value="" disabled>
                Veuillez choisir une filière
              </option>
              <option value="Bac +3 Ingénierie Logicielle (IL)">
                Licence Ingénierie Logicielle (IL)
              </option>
              <option value="Bac +5 ISITN">
              Master Ingénierie des Systèmes d'Information appliquée à la Transformation Numérique (ISITN)
                
              </option>
              <option value="Bac +5 IS2IA">
              Master Ingénierie des Systèmes d'Information appliquée à l'Intelligence Artificielle (IS2IA)


              </option>
            </select>
          </div>

          <div>
            <label>
              Comment souhaitez-vous être contacté ?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="contactmethod"
              value={contactMethod}
              onChange={(e) => setContactMethod(e.target.value)}
              required
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              <option value="">Choisissez une option</option>
              <option value="email">Par Email</option>
              <option value="phone">Par Téléphone</option>
            </select>
          </div>

          <div>
            <label>
              Quel est votre niveau d'études ?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="niveau"
              value={niveau}
              onChange={(e) => setNiveau(e.target.value)}
              required
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              <option value="">Choisissez un niveau d'étude</option>
              <option value="2emebac">Futur Bachelier 2025</option>
              <option value="2emebacok">Bachelier</option>
              <option value="bac+1">Bac +1</option>
              <option value="bac+2">Bac +2</option>
              <option value="bac+3">Bac +3</option>
              <option value="bac+4">Bac +4</option>
            </select>
          </div>

          <div>
            <label>
              Moyenne Générale ex: 14.25 <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="maths"
              maxLength="5"
              required
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                name="dataConsent"
                required
                checked={consent}
                onChange={handleConsentChange}
              />
              <span style={{ marginLeft: "10px" }}>
                Je consens à la collecte et à l'utilisation de mes données
                personnelles selon la{" "}
                <span
                  onClick={() => navigate("/politique-de-confidentialite")}
                  style={{
                    color: "#4cb6ea",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  politique de confidentialité
                </span>
                .
              </span>
            </label>
          </div>

          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA sitekey={SITE_KEY} onChange={onChangeCaptcha} />
          </div> */}

          <div style={{ textAlign: "center", display:"flex", justifyContent:"center" }}>
  <button
    type="submit"
    className="btnsubmitform"
  
  >
    {isLoading ? (
      <>
       <div className="mumspinner">
       <div className="custom-spinner2"></div>
       {/* {loadingPercentage > 0 && <span>{loadingPercentage}%</span>} */}
       </div>
      </>
    ) : (
      "Envoyer"
    )}
  </button>
</div>

        </form>

    
      </div>

      {showModal && (
        <div className="modal" style={modalStyle}>
          <div className="modal-content" style={modalContentStyle}>
            <button
              className="close"
              onClick={handleModalClose}
              style={closeButtonStyle}
            >
              &times;
            </button>
            <h2 style={modalHeaderStyle}>Préinscription Réussie</h2>
            <p style={modalTextStyle}>
              Félicitations, votre préinscription a été réalisée avec succès !
              Vous recevrez sous peu un email de confirmation.
            </p>
            <br />
            <strong>
              <p style={modalTextStyle2}>
                @COPYRIGHT © 2025 ESISA - TOUS DROITS RÉSERVÉS
              </p>
            </strong>
          </div>
        </div>
      )}
    </div>
  );
}

const modalStyle = {
  position: "fixed",
  zIndex: 1000,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
};

const modalContentStyle = {
  backgroundColor: "#fff",
  padding: "30px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  width: "90%",
  maxWidth: "500px",
  textAlign: "center",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "none",
  border: "none",
  fontSize: "20px",
  cursor: "pointer",
  color: "red",
  transition: "color 0.3s",
};

const modalHeaderStyle = {
  fontSize: "24px",
  color: "#333",
  marginBottom: "15px",
};

const modalTextStyle = {
  fontSize: "16px",
  color: "#555",
  lineHeight: "1.5",
};

const modalTextStyle2 = {
  fontSize: "12px",
  color: "#555",
  lineHeight: "1.5",
};

closeButtonStyle[":hover"] = {
  color: "#000",
};
