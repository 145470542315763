import React from 'react'
import "./footer.css"

export default function footer() {
  return (
      <footer class="iq_footer_bg">
            	<div class="container">
            		<div class="row">
            			<div class="col-md-3 col-sm-6" >
            				<div class="iq_uni_title">
            						<div class="iq_logo" style={{textAlign:"center"}}>
									<a href="images/logo/esisa.png"><img src="images/logo/esisa1.png" style={{width:'150px',height:'150px'}} alt="Iqoniq Themes"/></a>
									</div>
            					<ul>
            						<li><span>Lundi - Samedi</span> 08:00- 18:30</li>
            						<li><span>Dimanche</span> Fermé</li>
            					</ul>
            				</div>
            			</div>

            			<div class="col-md-3 col-sm-6" style={{width:'50%'}}>
                            <div class="widget widget_archive">
                                <h5 className='ptag' > ESISA</h5>
            					<p className='ptag'>Ecole supérieure d'ingénierie en Sciences Appliquées est une école fondée par un professionnel de l'informatique (Docteur, Professeur Universitaire, Ingénieur, PDG, Teneur du prix Sadi Carnot 1991), qui répond aux besoins du marché de travail de la région et de son développement.</p>
            				
                             
                            </div>
            			</div>
                       
            			<div class="col-md-3 col-sm-6">
            				<div class="widget iq_footer_newsletter">

            					<h5 style={{marginTop:"2rem" }} className="widget-title border-none mobileadded">Suivez-nous sur nos réseaux sociaux</h5>

            					<ul  className="iq_footer_social mobileadded gapax">
            						<li>
            							<a href="https://www.facebook.com/Esisa-Fes-Officiel-200236550123753/"><i className="fa fa-facebook"></i></a>
            						</li>
            						<li>
            							<a href="https://www.instagram.com/esisa.ac.ma/?hl=fr"><i className="fa fa-instagram"></i></a>
            						</li>
            						<li>
            							<a href="https://www.youtube.com/channel/UC9CoYqTDELRqa6Ft6oz-s0A"><i className="fa fa-youtube"></i></a>
            						</li>
									            						<li>
            							<a href="https://www.linkedin.com/school/esisa/mycompany/?viewAsMember=true"><i className="fa fa-linkedin"></i></a>
            						</li>
											            		
            					</ul>
            				</div>
            			</div>

            		</div>
            	</div>
            </footer>
  )
}
