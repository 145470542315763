import React, { useState, useEffect } from "react";
import "./Contactez_Nous.css";
import { CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify"; // Import de react-toastify
import { useNavigate } from "react-router-dom"; // Import pour la redirection avec useNavigate
import "react-toastify/dist/ReactToastify.css"; // Import des styles de toast
import ReCAPTCHA from "react-google-recaptcha";

const villesUniversitesEntreprises = {
  casablanca: {
    universites: [
      "École Hassania des Travaux Publics (EHTP)",
      "École Nationale Supérieure d'Électricité et de Mécanique (ENSEM)",
      "École Mohammedia d'Ingénieurs (EMI)",
      "École Nationale de l'Industrie Minérale (ENIM)",
      "École Supérieure d'Informatique et de Gestion (ESIG)",
      "Université Hassan II",
    ],
    entreprises: [
      "Atos",
      "Capgemini",
      "IBM Maroc",
      "SII Services Maroc",
      "INDATACORE",
      "OMAZIDCO",
      "NGT (New Generation Technology)",
      "D.B.M. (Data Business & Marketing)",
    ],
  },
  rabat: {
    universites: [
      "École Mohammadia d'Ingénieurs (EMI)",
      "Institut National des Postes et Télécommunications (INPT)",
      "École Nationale Supérieure d'Informatique et d'Analyse des Systèmes (ENSIAS)",
      "Université Internationale de Rabat (UIR)",
      "École Marocaine d'Ingénierie (EMI)",
      "École Supérieure d'Informatique et du Numérique (ESIN)",
    ],
    entreprises: [
      "Atos",
      "AXA Services Maroc",
      "Devoteam",
      "Archytas Conseil",
      "Cnexia",
      "Marketing Call Center",
    ],
  },
  fès: {
    universites: [
      "Université Sidi Mohamed Ben Abdellah",
      "École Nationale des Sciences Appliquées de Fès (ENSA Fès)",
      "École Supérieure de Technologie de Fès (EST Fès)",
    ],
    entreprises: [
      "Planet Soft",
      "Conseils & Solutions Progiciels (CSP)",
      "Storactive",
    ],
  },
  tanger: {
    universites: [
      "École Nationale des Sciences Appliquées de Tanger (ENSA Tanger)",
      "Faculté des Sciences et Techniques de Tanger (FST Tanger)",
      "École Nationale de Commerce et de Gestion de Tanger (ENCG Tanger)",
    ],
    entreprises: [
      "Orange Maroc",
      "Sopra Steria",
      "Capgemini",
      "Giantlink",
      "RightPlaceCall",
    ],
  },
  marrakech: {
    universites: [
      "Université Cadi Ayyad",
      "École Nationale des Sciences Appliquées de Marrakech (ENSA Marrakech)",
      "École Supérieure de Technologie de Marrakech (EST Marrakech)",
    ],
    entreprises: ["BIWARE", "ACCESS BUREAU", "THALÈS INFORMATIQUE"],
  },
  agadir: {
    universites: [
      "Université Ibn Zohr",
      "École Nationale des Sciences Appliquées d'Agadir (ENSA Agadir)",
      "École Supérieure de Technologie d'Agadir (EST Agadir)",
    ],
    entreprises: ["DISIS MAROC", "DATAPLUS (Data Plus)"],
  },
  autres: {
    universites: [
      "École Nationale de l'Intelligence Artificielle et du Digital (ENIAD) - Berkane",
      "École Nationale Forestière d'Ingénieurs (ENFI) - Salé",
      "École Nationale des Sciences Appliquées d'Al Hoceima (ENSA Al Hoceima)",
      "École Nationale des Sciences Appliquées de Berrechid (ENSA Berrechid)",
    ],
    entreprises: [
      "SHELLY-CO",
      "TECHTREND SOLUTIONS",
      "ADMIRAL DIGITAL CONSULTING",
      "INOR",
      "THUNDER TECHNOLOGY GROUP",
      "ILEM",
      "RODIN",
    ],
  },
};
const ContactezNous = () => {
    const [choix, setChoix] = useState("étudiant");
    const [formData, setFormData] = useState({
      nom: "",
      email: "",
      infoSup: "",
      message: "",
      ville: "",
      universite: "",
      entreprise: "",
      autreUniversite: "",
      autreEntreprise: "",
    });
    const [loading, setLoading] = useState(false);
    const [universites, setUniversites] = useState([]);
    const [entreprises, setEntreprises] = useState([]);
    const [isAutreUniversite, setIsAutreUniversite] = useState(false);
    const [isAutreEntreprise, setIsAutreEntreprise] = useState(false);
    const navigate = useNavigate();
    const SITE_KEY = "6LfhAQUqAAAAAGrtFFy0MRPFpw79Q35ymKgUZH-v";
    const [captchaToken, setCaptchaToken] = useState("");
    const [consent, setConsent] = useState(false);
  
    const handleConsentChange = (e) => {
      setConsent(e.target.checked);
    };
  
    useEffect(() => {
      if (formData.ville && villesUniversitesEntreprises[formData.ville]) {
        setUniversites(villesUniversitesEntreprises[formData.ville].universites);
        setEntreprises(villesUniversitesEntreprises[formData.ville].entreprises);
      } else {
        setUniversites([]);
        setEntreprises([]);
      }
    }, [formData.ville]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      setFormData((prevState) => ({ ...prevState, [name]: value }));
  
      if (name === "universite") {
        if (value === "autre") {
          setIsAutreUniversite(true);
          setFormData((prevState) => ({ ...prevState, universite: "" }));
        } else if (isAutreUniversite) {
          setIsAutreUniversite(false);
        }
      }
  
      if (name === "entreprise") {
        if (value === "autre") {
          setIsAutreEntreprise(true);
          setFormData((prevState) => ({ ...prevState, entreprise: "" }));
        } else if (isAutreEntreprise) {
          setIsAutreEntreprise(false);
        }
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (loading) return;
      if (!consent) {
        alert("Vous devez accepter le traitement des données.");
        return;
      }
      if (!captchaToken) {
        alert("Veuillez vérifier que vous n'êtes pas un robot.");
        return;
      }
      setLoading(true);
      const type = choix === "étudiant" ? "établissement" : "entreprise";
  
      try {
        const response = await fetch("https://esisa-portail-intranet-back.vercel.app/contact/contactUs", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...formData, type }),
        });
  
        if (!response.ok) throw new Error("Erreur lors de l'envoi du formulaire");
        toast.success("Message envoyé avec succès !");
        setFormData({
          nom: "",
          email: "",
          infoSup: "",
          message: "",
          ville: "",
          universite: "",
          entreprise: "",
          autreUniversite: "",
          autreEntreprise: "",
        });
        setTimeout(() => navigate("/"), 2000);
      } catch (error) {
        toast.error("Erreur lors de l'envoi. Veuillez réessayer.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="container">
        <h1 style={{color:"#4cb6ea", fontWeight:"bold", textAlign:"center", marginBottom:"3rem"}} className="title">Contactez-nous</h1>
  
        <div className="button-group">
          <button
            className={choix === "étudiant" ? "active" : ""}
            onClick={() => setChoix("étudiant")}
          >
            Je suis étudiant
          </button>
          <button
            className={choix === "salarié" ? "active" : ""}
            onClick={() => setChoix("salarié")}
          >
            Je suis salarié / entrepreneur
          </button>
        </div>
  
        <form
          style={{ display: "flex", flexDirection: "column" }}
          className="form"
          onSubmit={handleSubmit}
        >
          <label>Nom & Prénom</label>
          <input
            type="text"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            placeholder="Votre nom complet"
            required
          />
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Votre email"
            required
          />
          <label>Ville</label>
          <select
            name="ville"
            value={formData.ville}
            onChange={handleChange}
            required
          >
            <option value="">Sélectionnez une ville</option>
            {Object.keys(villesUniversitesEntreprises).map((ville) => (
              <option key={ville} value={ville}>
                {ville.charAt(0).toUpperCase() + ville.slice(1)}
              </option>
            ))}
          </select>
  
          {choix === "étudiant" && formData.ville && (
            <>
              <label>Université</label>
              <select style={{marginBottom:"2rem"}}
                name="universite"
                value={formData.universite}
                onChange={handleChange}
                required
              >
                 <option value="">Sélectionnez une université</option>
                 {universites.map((entreprise) => (
                  <option key={entreprise} value={entreprise}>
                    {entreprise}
                  </option>
                ))}
                <option value="autre">Autre</option>

              </select>
  
              {isAutreUniversite && (
                <input
                  type="text"
                  name="autreUniversite"
                  value={formData.autreUniversite}
                  placeholder="Entrez votre université"
                  onChange={handleChange}
                  required
                />
              )}
            </>
          )}
  
          {choix === "salarié" && formData.ville && (
            <>
              <label>Entreprise</label>
              <select style={{marginBottom:"2rem"}}
                name="entreprise"
                value={formData.entreprise}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez une entreprise</option>
                {entreprises.map((entreprise) => (
                  <option key={entreprise} value={entreprise}>
                    {entreprise}
                  </option>
                ))}
                <option value="autre">Autre</option>
              </select>
  
              {isAutreEntreprise && (
                <input
                  type="text"
                  name="autreEntreprise"
                  value={formData.autreEntreprise}
                  placeholder="Entrez votre entreprise"
                  onChange={handleChange}
                  required
                />
              )}
            </>
          )}
  
          <label>Message</label>
          <input
            type="text"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Votre message"
          />
  
          <label className="labelcheck">
            <input style={{margin:"2rem 0 2rem 0"}}
              type="checkbox"
              required
              checked={consent}
              onChange={handleConsentChange}
            />
            Je consens à la collecte et l'utilisation de mes données personnelles.
          </label>

          <div  style={{margin:"2rem 0 2rem 0"}}>
          <ReCAPTCHA sitekey={SITE_KEY} onChange={setCaptchaToken} />

          </div>
  
          <button className="buttonsendcontact" type="submit" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Envoyer"}
          </button>
        </form>
  
        <ToastContainer />
      </div>
    );
  };
  
  export default ContactezNous;
  